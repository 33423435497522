//values: [0, 1, 2],
// 任务管理的优先级枚举
export const ENUM_TASK_PRIORITY_VALIDE_TYPE = {
  ENUM_EVENT_TYPE_ZERO: {
    desc: '一般',
    value: 0,
  },
  ENUM_EVENT_TYPE_ONE: {
    desc: '重要',
    value: 1,
  },
  ENUM_EVENT_TYPE_TWO: {
    desc: '紧急',
    value: 2,
  },
}
//values: [0, 1, 2, 3],
// 任务管理的优先级枚举
export const ENUM_TASK_PRIORITY_TYPE = {
  ENUM_EVENT_TYPE_ZERO: {
    desc: '全部',
    value: 0,
  },
  ENUM_EVENT_TYPE_ONE: {
    desc: '一般',
    value: 1,
  },
  ENUM_EVENT_TYPE_TWO: {
    desc: '重要',
    value: 2,
  },
  ENUM_EVENT_TYPE_THREE: {
    desc: '紧急',
    value: 3,
  },
}
//values: [0, 1, 2, 3, 4, 5],
// 任务管理的状态枚举
export const ENUM_TASK_STATUS_TYPE = {
  ENUM_EVENT_TYPE_ZERO: {
    desc: '全部',
    value: 0,
  },
  ENUM_EVENT_TYPE_ONE: {
    desc: '待接收',
    value: 1,
  },
  ENUM_EVENT_TYPE_TWO: {
    desc: '处理中',
    value: 2,
  },
  ENUM_EVENT_TYPE_THREE: {
    desc: '待审核',
    value: 3,
  },
  ENUM_EVENT_TYPE_FOUR: {
    desc: '已结束',
    value: 4,
  },
  ENUM_EVENT_TYPE_FIVE: {
    desc: '已取消',
    value: 5,
  },
  // ENUM_EVENT_TYPE_FOUR: {
  //   desc: '已完成',
  //   value: 4,
  // },
  // ENUM_EVENT_TYPE_FIVE: {
  //   desc: '已废弃',
  //   value: 5,
  // },
}
