//values: [0, 1, 2],
// 事件管理的类型枚举
export const ENUM_EVENT_TYPE = {
  ENUM_EVENT_TYPE_ZERO: {
    desc: '待处理',
    value: 0,
  },
  ENUM_EVENT_TYPE_ONE: {
    desc: '已处理',
    value: 1,
  },
  ENUM_EVENT_TYPE_TWO: {
    desc: '已作废',
    value: 2,
  },
}
