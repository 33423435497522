import request from './request.js'
/**
 * 定义 GET POST PUT DELETE 请求
 * @param {*} url
 * @param {*} params
 */
const GET = (url, params, onErr) => {
    return request.get(url, params, onErr)
}
const POST = (url, params, onErr) => {
    return typeof params != undefined ? request.post(url, params, onErr) : request.post(url, null, onErr)
}
const PUT = (url, params, onErr) => {
    return typeof params != undefined ? request.put(url, params, onErr) : request.put(url.null, onErr)
}
const DELETE = (url, params, onErr) => {
    return typeof params != undefined ? request.delete(url, params, onErr) : request.delete(url, null, onErr)
}

// 登录
export const loginIn = (params) => {
    return POST('stationLogin', params)
}

// 修改密码
export const editPassword = (params, onErr) => {
    return POST('dispatchers/password', params, onErr)
}

// 获取集团下的所有用户
export const getAllTeamUsers = () => {
    return GET('AllTeamUsersName')
}

// 获取调度员管辖的所有用户
export const getUsersInchargeOf = () => {
    return GET('usersByOrg')
}

// 获取集团下所有用户在线状态
export const userStatusByTeam = (params) => {
    return GET('userStatusByTeam', params)
}

// 查询卫星电话是否设备在线
export const isSatelOnline = (params) => {
    return GET('isSatelOnline', params)
}

// 获取正在推流的用户列表
export const pushvideolist = (params) => {
    return GET('pushvideolist', params)
}

// 获取用户所在的所有群组
export const teamAllGroup = (params) => {
    return GET('groups/teamAllGroup', params)
}

// 获取所有围栏及其组成点
export const getFencesWithPoints = (params, onErr) => {
    return GET('fencesWithPoints', params, onErr)
}

// 获取所有围栏列表
export const getFences = (params, onErr) => {
    return GET('fence', params, onErr)
}

// 获取围栏详情
export const getFenceId = (params) => {
    return GET('fence/' + params.id)
}

// 获取所有标注信息
export const getAnnotationList = (params) => {
    return GET('getAnnotationList', params)
}

// 获取所有巡更点信息
export const getBeacons = (params) => {
    return GET('beacon', params)
}

// 修改地图标注信息
export const modifyAnnotation = (params) => {
    return POST('modifyAnnotation', params)
}

// 删除地图标注信息
export const deleteAnnotation = (params) => {
    return GET('deleteAnnotation', params)
}

// 单个添加地图标注信息
export const createAnnotation = (params, onErr) => {
    return POST('createAnnotation', params, onErr)
}

// 批量添加地图标注信息
export const createAnnotationBatch = (params) => {
    return POST('createAnnotationBatch', params)
}

// 创建巡更点
export const createBeacon = (params) => {
    return POST('beacon', params)
}

// 人员轨迹查询
export const passByUsers = (params) => {
    return GET('passby/users', params)
}

// 创建围栏
export const createFence = (params) => {
    return POST('fence', params)
}

// 修改围栏
export const editFence = (params) => {
    return PUT('fence', params)
}

// 删除围栏
export const delFence = (params) => {
    return DELETE('fence', { data: params })
}

// 围栏告警记录
export const fenceRecord = (params) => {
    return GET('fence/alerts', params)
}

// 删除围栏告警记录
export const delFenceRecord = (params) => {
    return DELETE('fence/alert?alertid=' + params.alertid)
}

// 围栏告警记录详情
export const FenceRecord = (params) => {
    return GET('fence/alert', params)
}

// 获取用户所在的组织结构
export const getOrgTree = (params) => {
    return GET('orgs', params)
}

// 获取组织结构下的用户
export const usersOrg = (params) => {
    return GET('users/org', params)
}

// 拉流-多个用户
export const morePullvideo = (params) => {
    return GET('pullvideo' + params.Splicing)
}

// 拉流-单个用户
export const pullvideo = (params) => {
    return GET('pullvideo', params)
}

// 事件查询
export const events = (params) => {
    return GET('events', params)
}

// 获取事件的媒体文件
export const eventattaches = (params) => {
    return GET('eventattaches', params)
}

// 事件确认
export const confirmEvent = (params) => {
    return PUT('event/confirmed?id=' + params.id)
}

// 事件删除
export const deleteEvent = (params) => {
    return DELETE('events?eventIds=' + params.eventIds)
}

// 查询打卡记录
export const checkinRecords = (params) => {
    return GET('checkin/records', params)
}

// 查询班次
export const WorkShifts = (params) => {
    return GET('WorkShifts', params)
}

// 添加班次
export const addWorkShifts = (params) => {
    return POST('WorkShifts', params)
}

// 修改班次
export const editWorkShifts = (params) => {
    return PUT('WorkShifts', params)
}

// 删除班次
export const delWorkShifts = (params) => {
    return DELETE('WorkShifts/' + params.id)
}

// 排班列表
export const workSchedule = (params) => {
    return GET('workSchedule', params)
}

// 请假列表
export const absenceRequests = (params) => {
    return GET('absenceRequests', params)
}

/**
 * @function
 * @description 请假审核
 * @param {Object} params - 请假审核信息
 * @param {Number} params.id - 请假id
 * @param {Boolean} params.approved - 是否通过
 * @param {String}  params.commont - 审核备注
 * */
export const absenceApproved = (params) => {
    return PUT('absenceRequest/approved?id=' + params.id + '&approved=' + params.approved + '&commont=' + params.commont)
}

// 修改巡更点
export const editBeacon = (params) => {
    return PUT('beacon', params)
}

// 删除巡更点
export const delBeacon = (params) => {
    return DELETE('beacon', { data: params })
}

// 巡更路线列表
export const routes = (params) => {
    return GET('routes', params)
}

// 巡更路线编辑
export const editRoute = (params) => {
    return PUT('route', params)
}

// 巡更路线删除
export const delRoute = (params) => {
    return DELETE('route', { data: params })
}

// 巡更路线详情
export const route = (params) => {
    return GET('route', params)
}

// 创建巡更路线
export const createRoute = (params) => {
    return POST('route', params)
}

// 巡更记录
export const routeRecode = (params) => {
    return GET('routeRecode', params)
}

// 巡更记录详情
export const getrouteRecord = (params) => {
    return GET('getrouteRecord', params)
}

// 巡更计划列表
export const routeRecordPlan = (params) => {
    return GET('routeRecordPlan', params)
}

// 添加巡更计划
export const createRouteRecordPlan = (params) => {
    return POST('routeRecordPlan', params)
}

// 编辑巡更计划
export const editRouteRecordPlan = (params) => {
    return PUT('routeRecordPlan', params)
}

// 删除巡更计划
export const DelRouteRecordPlan = (params) => {
    return DELETE('routeRecordPlan?recordId=' + params.recordId)
}

// 任务列表
export const tasks = (params) => {
    return GET('tasks', params)
}

// 获取任务分类信息及任务关联的组织结构id
export const tasksClasses = (params) => {
    return GET('tasksClasses', params)
}

// 获取组织的下级组织及用户
export const usersOrgtree = (params) => {
    return GET('users/orgtree', params)
}

// 获取集团的组织结构，扁平化不是树状
export const getOrgsFlat = (params) => {
    return GET('orgsFlat', params)
}

// 获取任务详情
export const taskInfo = (params) => {
    return GET('taskInfo', params)
}

// 删除任务
export const deleteTask = (params) => {
    return DELETE('deleteTask', {data:params})
}

// 审核任务通过
export const checkTask = (params) => {
    return POST('checkTask', params)
}

// 审核任务驳回
export const rejectTask = (params) => {
    return POST('rejectTask', params)
}

// 分配任务
export const dispatchTask = (params) => {
    return POST('dispatchTask', params)
}

// 获取任务优先级设置
export const tasksPriorities = (params) => {
    return GET('tasksPriorities', params)
}

// 修改任务分类及指定关联的组织结构id
export const tasksClass = (params) => {
    return POST('tasksClass', params)
}

// 创建任务分类及指定关联的组织结构id
export const createTasksClass = (params) => {
    return PUT('tasksClass', params)
}

// 删除任务分类
export const delTasksClass = (params) => {
    return DELETE('tasksClass?taskClassId=' + params.taskClassId)
}

// 删除任务项目组
export const delProject = (params) => {
    return DELETE('project?projectIds=' + params.projectIds)
}

// 修改任务优先级设置
export const tasksPriority = (params) => {
    return POST('tasksPriority', params)
}

// 更新任务项目组
export const editProject = (params) => {
    return POST('project', params)
}

// 创建任务项目组
export const createProject = (params) => {
    return PUT('project', params)
}

// 创建任务
export const createTask = (params) => {
    return PUT('createTask', params)
}

// 媒体记录列表
export const mediaRecord = (params) => {
    return GET('mediarecord/list', params)
}

// 媒体记录下载
export const mediaRecordDownload = (params) => {
    return POST('mediarecord/download', params)
}

// 媒体记录删除
export const mediaRecordDelete = (params) => {
    return DELETE('mediarecord/delete', { data: params })
}

// 消息助手列表
export const notifications = (params) => {
    return GET('notifications', params)
}

// 消息助手设为已读
export const readNotifications = (params) => {
    return POST('notifications', params)
}

// 消息助手删除
export const delNotifications = (params) => {
    return DELETE('notifications', { data: params })
}

// sos报警详情
export const sosalert = (params) => {
    return GET('sosalert', params)
}

// 区域划分列表
export const annotationRegion = (params) => {
    return GET('annotationRegion', params)
}

// 区域详情
export const annotationRegionId = (params) => {
    return GET('annotationRegion/' + params.id)
}

// 删除区域
export const delAnnotationRegion = (params) => {
    return DELETE('annotationRegion', { data: params })
}

// 修改区域
export const editAnnotationRegion = (params) => {
    return PUT('annotationRegion', params)
}

// 创建区域
export const createAnnotationRegion = (params) => {
    return POST('annotationRegion', params)
}

// 视频会议列表
export const ongoingMeetingList = (params) => {
    return GET('ongoingMeetingList', params)
}

// 已结束的视频会议列表
export const isMissMeetingList = (params) => {
    return GET('isMissMeetingList', params)
}

// 拒绝一个视频会议
export const meetingRefuse = (params) => {
    return GET('meetingRefuse', params)
}

// 创建视频会议
export const meetingCreate = (params) => {
    return POST('meetingCreate', params)
}

// 正在进行的会议踢人
export const meetingKickOutByList = (params) => {
    return POST('meetingKickOutByList', params)
}

// 正在进行的会议邀人
export const meetingInviteByList = (params) => {
    return POST('meetingInviteByList', params)
}

// 正在进行的设置发言人
export const meetingspeaker = (params) => {
    return POST('meetingspeaker?meetingId=' + params.meetingId + '&speakerId=' + params.speakerId)
}

// 解散会议
export const meetingEnd = (params) => {
    return GET('meetingEnd', params)
}

// 视频会议回放录像
export const videorecord = (params) => {
    return GET('videorecord', params)
}

// 调整视频清晰度参数
export const updateVideoConfig = (params) => {
    return POST('updateVideoConfig', params)
}

// 取消视频推流
export const stopDeviceVideo = (params) => {
    return POST('stopDeviceVideo?room='+params.room, params)
}

// 查看已分享人员
export const videoShareList = (params) => {
    return GET('videoShareList', params)
}

// 取消已分享的人员
export const unsharevideo = (params) => {
    return POST('unsharevideo', params)
}

// 视频分享给人员
export const sharevideo = (params) => {
    return POST('sharevideo', params)
}

// 创建视频会商
export const videocall = (params) => {
    return POST('videocall', params)
}

// 创建语音会议
export const audiocall = (params) => {
    return POST('audiocall', params)
}

// 获取群组成员
export const memberStatus = (params) => {
    return GET('memberStatus', params)
}

// 获取群组成员数据
export const groupUsers = (params) => {
    return GET('users/group/in', params)
}

// 删除临时组
export const delTemporaryGroup = (params) => {
    return DELETE('delTemporaryGroup?gids=' + params.gids)
}

// 退出临时组
export const quitTemporaryGroup = (params) => {
    return GET('quitTemporaryGroup', params)
}

// 创建临时组
export const createAdhoc = (params) => {
    return POST('temporaryGroups', params)
}

// 修改临时组
export const putTemporaryGroups = (params) => {
    return PUT('temporaryGroups', params)
}

// 临时组用户修改
export const members = (params) => {
    return PUT('members', params)
}

// 预定义组录音记录
export const getVoiceRecordList = (params) => {
    return GET('getVoiceRecordList', params)
}

// 群组短信息
export const groupmessage = (params) => {
    return GET('groupmessage', params)
}

// 发送短信息
export const postGroupmessage = (params) => {
    return POST('groupmessage', params)
}

// 获取单个用户的定位配置
export const getUserLocconfig = (params) => {
    return GET('users/locconfig', params)
}

// 修改用户的定位配置(网络上报)
export const postUserLocconfig = (params) => {
    return POST('users/locconfig', params)
}

// 修改用户的定位配置(短信上报)
export const postUserLocconfigsms = (params) => {
    return POST('users/locconfigsms', params)
}
// 云天获取单个用户的定位配置(卫星电话)
export const getUserLocconfigSatellite = (params) => {
    return GET('users/locconfigSatellite', params)
}
// 云天修改用户的定位配置(卫星电话)
export const postUserLocconfigSatellite = (params) => {
    return POST('users/locconfigSatellite', params)
}
// 获取用户视频上传记录
export const getUserVideorecord = (params) => {
    return GET('videorecord', params)
}

// 用户立即定位
export const locImmediately = (params) => {
    return GET('locImmediately', params)
}

// 坐标转换
export const locConvert = (params) => {
    return GET('locConvert', params)
}

export const getTracksOfUser= (params)=>{
    return GET("tracks/users", params)
}
// 公安获取保安公司列表和Org
export const securityTeamOrg= (params)=>{
    return GET("securityTeamOrg", params)
}
// 查询姓名和集团名称信息
export const userInfo= (params)=>{
    return GET("userInfo", params)
}
// 拒绝、挂断视频呼叫
export const exitVideoCall= (params)=>{
    return GET("exitVideoCall", params)
}
// 查询厂区列表
export const factory= (params)=>{
    return GET("factory", params)
}
// 查询建筑物列表
export const building= (params)=>{
    return GET("building", params)
}
// 查询定位信标点列表
export const locationBeacon= (params)=>{
    return GET("locationBeacon", params)
}
// 查询信号预采点列表
export const samplePoint= (params)=>{
    return GET("samplePoint", params)
}
// 查询已选择的楼层
export const dispatcherFloor= (params)=>{
    return GET("dispatcherFloor", params)
}
// 存储选择的楼层
export const saveDispatcherFloor= (params)=>{
    return POST("dispatcherFloor", params)
}
// 查询用户上下线记录
export const usersRecords= (params)=>{
    return GET("users/records", params)
}
// 群组消息设为已读
export const GroupMsgRead= (params)=>{
    return GET("GroupMsgRead", params)
}
// 获取语音播报记录
export const getVoiceBroadcast= (params)=>{
    return GET("voiceBroadcast", params)
}
// 创建语音播报
export const createVoiceBroadcast= (params)=>{
    return POST("voiceBroadcast", params)
}
// 编辑语音播报
export const editVoiceBroadcast= (params)=>{
    return PUT("voiceBroadcast", params)
}
// 删除语音播报
export const delVoiceBroadcast= (params)=>{
    return DELETE("voiceBroadcast/"+params.id)
}
// 获取语音播报人员
export const voiceDetails= (params)=>{
    return GET("voiceDetails", params)
}
// 立即播放语音
export const immediately= (params)=>{
    return GET("immediately", params)
}
// 获取定位打卡记录
export const getLocCheckin= (params)=>{
    return GET("locCheckin", params)
}