//values: [1, 2],
// 电子围栏的类型枚举
export const ENUM_FENCE_TYPE = {
  ENUM_FENCE_TYPE_ONE: {
    desc: '禁入',
    value: 1,
  },
  ENUM_FENCE_TYPE_TWO: {
    desc: '禁出',
    value: 2,
  },
}
