//record_status.status
//values: [0, 1, 2],
// 巡更路线的巡更状态类型枚举
export const ENUM_ROUTE_RECORD_STATUS = {
    STATUS_UNCOMPLETED: {
        desc: '未完成',
        value: 0,
    },
    STATUS_COMPLETED: {
        desc: '合规完成',
        value: 1,
    },
    STATUS_ILLEGAL: {
        desc: '违规完成',
        value: 2,
    },
}