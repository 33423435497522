/**
 * 时间转换成格式串，没有空格，冒号等，例如 20221231_235900
 * @param date {Date}
 * @return {String} 本地时间年月日时分秒， 24小时制
 *
 */
function dateToFileNameString(date) {
    if (!date) {
        return '00000000_000000'
    }
    var Y = date.getFullYear()
    var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return '' + Y +  M +  D + '_' + h +  m + s
}

/**
 * 时间转换成格式串,例如 2022-12-31 23:59:00
 * @param date {Date}
 * @return {String} 本地时间年月日时分秒， 24小时制
 * 
 */
function dateToDateTimeString(date) {
    if (!date) {
        return '0000-00-00 00:00:00'
    }
    var Y = date.getFullYear()
    var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
}


/**
 * 时间转换成格式串,例如 2022-12-31 23:59:00
 * @param timestamp
 * @returns {string}
 */
function timestampToTime(timestamp) {
    if(!timestamp){
        return '0000-00-00 00:00:00'
    }
    //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    if (String(timestamp).length == 10) {
        timestamp = timestamp * 1000
    }
    return dateToDateTimeString(new Date(timestamp))
}

// 时间转换
function resolvingDate(date) {
    let d = new Date(date)
    let hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
    let min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
    return hours + ':' + min
}
//本地时间转换为UTC时间
function UTCToLocalTime(seconds) {
    seconds = seconds + 8 * 3600
    if (seconds > 24 * 3600) {
        seconds = seconds - 24 * 3600
    }
    return seconds
}
// 所有时间均减去八小时 北京时间在东八区减去八小时为标准时间
function LocalTimeToUtcStart(seconds) {
    seconds = seconds - 8 * 3600
    if (seconds < 0) {
        seconds = seconds + 24 * 3600
    }
    return seconds
}

/**
 * 秒数：所有时间均减去八小时 北京时间在东八区减去八小时为标准时间
 * @param seconds
 * @returns {number}
 * @constructor
 */
function LocalTimeToUtc(seconds) {
    seconds = seconds - 8 * 3600
    if (seconds <= 0) {
        seconds = seconds + 24 * 3600
    }
    return seconds
}
// utc转换为时分
function enclosureDateFormat(second) {
    var dd, hh, mm, ss
    second = typeof second === 'string' ? parseInt(second) : second
    if (!second || second < 0) {
        return '00:00'
    }
    //天
    dd = (second / (24 * 3600)) | 0
    second = Math.round(second) - dd * 24 * 3600
        //小时
    hh = (second / 3600) | 0
    second = Math.round(second) - hh * 3600
        //分
    mm = (second / 60) | 0
    if (Math.round(hh) < 10) {
        hh = '0' + hh
    }
    if (Math.round(mm) < 10) {
        mm = '0' + mm
    }
    return hh + ':' + mm
}

/**
 * 导出Excel表格
 * @param tHeader
 * @param filterVal
 * @param list
 * @param flieName
 */
function export2Excel(tHeader, filterVal, list, flieName) {
    let { export_json_to_excel } = require('./vendor/Export2Excel.js')
    let data = formatJson(filterVal, list)
    export_json_to_excel(tHeader, data, flieName)
}

function formatJson(filterVal, jsonData) {
    return jsonData.map((v) => filterVal.map((j) => v[j]))
}

/**
 * 文件下载
 * @param url
 * @param filename
 */
function downloadUrlFile(url, filename) {
    var eleLink = document.createElement('a')
    eleLink.style.display = 'none'
    eleLink.href = url //URL.createObjectURL(blob);
    if (filename) {
        eleLink.download = filename
    }
    // 触发点击
    document.body.appendChild(eleLink)
    eleLink.click()
    // 然后移除
    document.body.removeChild(eleLink)
}

/**
 * 一键报警声音播放
 * @constructor
 */
function WarningTonePlay() {
    // localStorage.getItem('alarmType') 0静音 2正常
    if (localStorage.getItem('alarmType') == 2) {
        let global_audioElementHovertree = document.createElement('audio')
        global_audioElementHovertree.setAttribute('autoplay', true) //打开自动播放
        global_audioElementHovertree.muted = false //是否静音
        global_audioElementHovertree.setAttribute('src', 'https://me-oss.oss-cn-qingdao.aliyuncs.com/web/SOS_alarm.mp3')
    }
}

/**
 * 获取验证码和登录的时候需要获取并传入uuid，解决不能多个用户同事登录的问题
 * @returns {string}
 */
function getUuid() {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    var uuid = [],
        i

    for (i = 0; i < 8; i++) uuid[i] = chars[0 | (Math.random() * 32)]
    uuid[8] = new Date().getTime()

    return uuid.join('')
}

export default {
    timestampToTime,
    export2Excel,
    resolvingDate,
    LocalTimeToUtcStart,
    LocalTimeToUtc,
    enclosureDateFormat,
    UTCToLocalTime,
    downloadUrlFile,
    WarningTonePlay,
    dateToDateTimeString,
    dateToFileNameString,
    getUuid
}