import Vue from 'vue'
import Vuex from 'vuex'

import VueEvent from '../js/common/VueEvent.js'
import Mqtt from '../js/Mqtt'
import Utils from '../js/utils'
import voiceWebsocket from '../js/voiceWebsocket'
import axios from "axios";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: '', //请求地址
    token: '', //token
    config: {}, //config.json配置文件信息

    /**
     * @field 本集团下所有用户信息列表
     * 数组下标为userId
     * 包含字段：
     * uid: {Number} userId
     * name: {String} 用户名，本机显示 “本机”
     * pinyin: {String} 姓名拼音
     * monitor：{Boolean} 是否在地图上显示，始终为true
     * locPermission：{Boolean} 是否有定位权限 ，始终为true
     * talkStatus: {Boolean}, // 是否在线（卫星电话用是否有定位作为是否在线）
     * online:  {Boolean}, // 是否在线
     * curGroupId:{Number}  当前守护组Id，0代表无守护组
     * inChargeOf: {Boolean}, // 是否属于当前调度员管辖
     * toDelete: {Boolean}, //临时属性标志，用于差分
     * realUrl: {String}  正在推流的url, null 代表未在推流
     * mdn: {String} 手机号，非管辖用户的手机号不赋值
     * liveVideo: {Boolean} 用户套餐是否具有视频功能，非管辖用户显示未false
     * orgId: 0 用户所属的组织结构id 非管辖用户赋值为0
     * icon： 定位显示图标 非管辖用户赋值为0
     * comment： 用户备注信息
     * pullBy: 拉起终端视频推流的用户id
     * teamName:集团名称
     * orgName:组织结构名称
     * teamId:集团id
     * isFollowLoc:用户是否开启定位跟踪
     * factoryId:厂区id
     * buildingId:建筑物id
     * floorId:楼层id
     * batteryLevel:电量
     * powerOn:卫星电话是否开机
     * satellitePhone:是否是卫星电话
     * imei：用户的IMEI
     * locationInfo: {Object {
     *      time: {Date} 定位时间
     *      lng: {Number}经度
     *      lat：{Number}纬度
     *      lngBd09: {Number}百度经度
     *      latBd09: {Number}百度纬度
     *      lngGcj: {Number}国测局经度,
     *      latGcj: {Number} 国测局纬度
     *      altitude：{Number} 海拔高度（单位米）
     *      velocity：{Number} 速度（单位米/秒）
     *      heading: {Number} 朝向 (单位角度，0~360, 正北为0度，顺时针)
     *      locAccuracy：{Number} 精度，单位米
     *      locMode：{Number} 定位模式， 3= GPS， 4=网络定位
     *      locSpan：{Number} 定位间隔，单位秒
     *  }}  用户最后一次的位置数据，null 代表无定位信息
     * outdoor: {Boolean} 是否室外
     */
    allUserList: [], // {Object[]}

    // 统计数据
    onlineCount: 0, // 在线人数
    inroomCount: 0, // 室内人数
    outdoorCount: 0, // 室外人数
    offlineCount: 0, // 离线人数

    /**
     * 调度员所在的所有群组，包括临时组
     * 数组下标为groupId
     * 包含字段：
     * id: {Number} 组id
     * name: {String}组名称
     * groupType：{Number} 1=预定义组 2= 临时组
     * priority： {Number} 优先级
     * userList: {Number}[] 成员uid列表
     * guardCount: {Number} 在线守护本组的人数
     * onlineCount: {Number} 本组在线的人数
     */
    allGroupList: [],

    /**
             * @field 所有围栏信息
             *  数组下标：fenceId
             *  字段：
             *  id: {Number} 围栏id
                name: {String} 围栏名称
                startSec: {Number} 开始时间，从UTC 00:00 开始的秒数
                endSec: {Number} 结束时间，从UTC 00:00 开始的秒数
                managerId: {Number} 创建人,
                teamId: 集团id
                color:  围栏颜色 "#B71B1B",
                lngLats: {LngLat[]}  组成围栏的坐标点列表 
                    LngLat 字段:
                    {
                        longitude: {Number} WGS84经度,
                        latitude: {Number} WGS84纬度,
                        lngGcj: {Number} 国测局经度,
                        latGcj: {Number} 国测局纬度,
                        lngBd09: {Number} 百度经度,
                        latBd09: {Number} 百度纬度,
                    }  
             */
    allFenceList: [],

    /**
     * 区域划分数据，下标是区域Id
     */
    allRegionList:[],

    /**
     * 标注信息列表, 下标是顺序号
     */
    allTaggingList: [],

    /**
     * @field {Object[]}
     *
     * 巡更点信息列表, 下标是顺序号
     * */
    allBeaconList: [],

    showOnMap: {
      // 各类标注是否显示
      online: true,
      inroom: true,
      outdoor: true,
      offline: true,
      fence: false,
      tagging: false, // 标注
      beacon: false, //巡更点
      locationBeacon:false, //信标
      samplePoint:false, //预采点
      camera:false, //摄像头
    },

    /**
     * 立即定位的uid, HashMap, key = uid, value= 非空的Object
     */
    locImmediatelyUidList: new Map(),

    /**
     * 开启定位跟踪的uid
     */
    followLocUidList: [],

    speakerId: null, //正在对讲的用户id
    /**
     *{Boolean} 记录登录身份过期是否已提示过 true已过期 false未过期, 避免重复提示登录已过期
     */
    isTokenInvalid: false,
    myUserId: 0, // {Number} 当前登录用户的userId
    myTeamId: 0, // {Number} 当前登录用户的 teamId
    myTeamName: '', // {String} 当前登录用户的集团名称
    myUserInfoText: '', //{String} 当前登录用户姓名和所属公司
    myOrgId: 0, // {Number} 当前登录用户的组织结构id
    showTips: false, //语音服务器登录提示是否展示
    loginStates: '', //语音服务器登录提示文字
    messageTipsShow: false, //消息助手是否有未读消息(红点是否展示)

    orgTreeMap: null, // HashMap<Number, Number[]> , 每个org的本机及上级（递归包含上上级）的orgId列表
    orgTree: null, // 请求得到原始返回数据
    /**
     * 获取集团下的所有组织结构，扁平化不是树状
     */
    orgsFlat:[],
    /**
     * 调度员能管辖的所有org的列表
     * {Number}[]
     * */
    subOrgs: [],
    /**
     * {Number} 视频状态
     * 0=无操作 1=观看视频推流 2=单人视频会商 3=多人视频会商 4=参加不是自己创建的会议 5=参加自己创建的会议 6=终端推流回放 7=会议回放
     */
    videoState: 0, //视频状态
    /**
     * 首页需要获取的数据是否获取成功
     */
    informationCompletion: {
      userApiCompletion: false, //用户信息
      orgApiCompletion: false, //组织结构
      fenceApiCompletion: false, //围栏
      groupApiCompletion: false, //群组
      taggingApiCompletion: false, //标注点
      beaconApiCompletion: false, //巡更点
      factoryApiCompletion:false, //厂区地图和建筑物
      locationBeaconApiCompletion: false, //信标点
      samplePointApiCompletion: false, //预采点
      policeCameraApiCompletion: false, //山东警保摄像头
    },
    /**
     * 最近对讲群组list
     * 包含字段：
     * id：{number} 群组id
     * name: {string} 群组名称
     */
    recentGroupList: [],
    /**
     * 所有的厂区列表 下标为厂区id
     * {
     * ewsn
     *     createDate:{Date} 创建时间
     *     creatorId:{number} 创建人
     *     deepColorMapUrl:{string} 深色厂区底图地址
     *     eastBd09:{number} 厂区西百度经度
     *     eastGcj:{number} 厂区西高德经度
     *     eastWgs84:{number} 厂区西gps经度
     *     id:{number} 厂区id
     *     lightColorMapUrl:{string} 浅色厂区底图地址
     *     name:{string} 厂区名称
     *     northBd09:{number} 厂区北百度纬度
     *     northGcj:{number} 厂区北高德纬度
     *     northWgs84:{number} 厂区北gps纬度
     *     southBd09:{number} 厂区南百度纬度
     *     southGcj:{number} 厂区南高德纬度
     *     southWgs84:{number} 厂区南gps纬度
     *     teamId:{number} 集团id
     *     westBd09:{number} 厂区东百度经度
     *     westGcj:{number} 厂区东高德经度
     *     westWgs84:{number} 厂区东gps经度
     * }
     */
    allFactoryList:[],
    /**
     * 所有的建筑物列表，下标为建筑物id
     * {
     *     color:{string} 建筑物颜色
     *     eastBd09:{number} 建筑物东百度经度
     *     eastGcj:{number} 建筑物东高德经度
     *     eastWgs84:{number} 建筑物东gps经度
     *     factoryId:{number} 厂区id
     *     floors:建筑物楼层信息[
     *         {
     *             buildingId:{number} 建筑物id
     *             createDate:{Date} 常见时间
     *             creatorId:{number} 创建人id
     *             deepColorMapUrl:{string} 深色楼层图地址
     *             id:{number} 楼层id
     *             lightColorMapUrl:{string} 浅色楼层图地址
     *             name:{string} 楼层名称
     *             number:{number} 楼层号
     *         }
     *     ]
     *     height:{number} 建筑物高度
     *     id:{number} 建筑物id
     *     lngLats:建筑物的坐标点集合[
     *         {
     *             buildingId:{number} 建筑物id
     *             id:{number} 坐标id
     *             latBd09:{number} 百度纬度
     *             latGcj:{number} 高德纬度
     *             latitude:{number} gps纬度
     *             lngBd09:{number} 百度经度
     *             lngGcj:{number} 高德经度
     *             longitude:{number} gps经度
     *         }
     *     ]
     *     name:{string} 建筑物名称
     *     northBd09:{number} 建筑物北百度纬度
     *     northGcj:{number} 建筑物北高德纬度
     *     northWgs84:{number} 建筑物北gps纬度
     *     southBd09:{number} 建筑物南百度纬度
     *     southGcj:{number} 建筑物南高德纬度
     *     southWgs84:{number} 建筑物南gps纬度
     *     westBd09:{number} 建筑物西百度经度
     *     westGcj:{number} 建筑物西高德经度
     *     westWgs84:{number} 建筑物西gps经度
     * }
     */
    allBuildingList:[],
    /**
     * 所有的定位信标列表 下标为信标顺序号
     * {
     *     beaconType:{number} 0=wifi标签 1=蓝牙标签
     *     buildingId:{number} 建筑物id
     *     factoryId:{number} 厂区id
     *     floorId:{number} 楼层id
     *     id:{string} 信标id
     *     latBd09:{number} 百度纬度
     *     latGcj:{number} 高德纬度
     *     latitude:{number} gps纬度
     *     lngBd09:{number} 百度经度
     *     lngGcj:{number} 高德经度
     *     longitude:{number} gps经度
     *     sn:{string} 信标名称
     *     teamId:{number} 集团id
     * }
     */
    allLocationBeaconList:[],
    /**
     * 所有的预采点列表 下标为预采点id
     * {
     *     buildingId:{number} 建筑物id
     *     factoryId:{number} 厂区id
     *     floorId:{number} 楼层id
     *     id:{number} 预采点id
     *     latBd09:{number} 百度纬度
     *     latGcj:{number} 高德纬度
     *     latitude:{number} gps纬度
     *     lngBd09:{number} 百度经度
     *     lngGcj:{number} 高德经度
     *     longitude:{number} gps经度
     *     name:{string} 预采点名称
     *     teamId:{number} 集团id
     *     locationBeaconIdRssiList:采集到的信标列表[
     *         {
     *             beaconId:{string} 信标id
     *             rssi:{number} 采样点到此信标的信号强度
     *         }
     *     ]
     * }
     */
    allSamplePointList:[],
    /**
     * 左侧主菜单是否显示名称
     */
    displayName:false,
    /**
     * 是否全屏
     */
    fullScreen:false,
    /**
     * 用户上下线记录是否放大展示
     */
    showRecordsScreen:true,
    /**
     * 弹窗距离左侧边距
     */
    formsLeft:100,
    // 用户切换主题时更换图片url
    closeImgUrl: require('../assets/enclosure/enclosure_menu_close1.png'), // 页面关闭菜单按钮
    deleteImgUrl: require('../assets/helperMenu/icon_recorddelete_nor1.png'), // 删除按钮
    callImgUrl: require('../assets/channelSessionMenu/group_ico_horn3.png'), //创建会话按钮
    pullImgUrl: require('../assets/channelSessionMenu/livebroadcast1.png'), //拉流按钮
    videoImgUrl: require('../assets/channelSessionMenu/livebroadcast2.png'), //查看直播按钮
    consultaImgUrl: require('../assets/channelSessionMenu/Initiate_video.png'), //视频会商按钮
    audioCallImgUrl: require('../assets/index/audiocall.png'), //语音会议按钮
    exportExcel: require('../assets/index/exportExcel.png'), //导出Excel
    locConfigImgUrl: require('../assets/mainFrame/talk_ico_set_b1.png'), //定位配置按钮
    locImmediaImgUrl: require('../assets/channelSessionMenu/icon_dingwei21.png'), //立即定位按钮
    userCenterImgUrl: require('../assets/mainFrame/focus1.png'), //居中显示按钮
    videoRecordImgUrl: require('../assets/channelSessionMenu/uploadrecord.png'), //视频上传记录按钮
    locTrackImgUrl: require('../assets/mainFrame/genzong.png'), //定位跟踪
    closeLocTrackImgUrl: require('../assets/mainFrame/genzong1.png'), //关闭定位跟踪
    callImgUrl1: require('../assets/mainFrame/huihua.png'), //创建会话按钮
    openImgUrl: require('../assets/index/zhankai.png'), //展开群组成员按钮
    closeImgUrl1: require('../assets/index/shouqi.png'), //关闭群组成员按钮
    editImgUrl1: require('../assets/index/edit.png'), //围栏编辑按钮
    memberImgUrl1: require('../assets/index/member.png'), //围栏成员管理按钮
    setImgUrl: require('../assets/index/set.png'), //围栏设置按钮
    dissMeetImgUrl: require('../assets/index/closemeet.png'), //解散会议按钮
    joinMeetImgUrl: require('../assets/index/addmeet.png'), //加入会议按钮
    playImgUrl: require('../assets/index/bofang.png'), //视频播放按钮
    minImgUrl: require('../assets/index/zuixiaohua.png'), //视频窗口最小化按钮
    maxImgUrl: require('../assets/index/zuidahua.png'), //视频窗口最大化按钮
    reductionImgUrl: require('../assets/index/xiangxiahuanyuan.png'), //视频窗口向下还原按钮
    restoreImgUrl: require('../assets/index/restore.png'), //视频最小化窗口恢复按钮
    fluentImgUrl: require('../assets/index/liuchang.png'), //视频清晰度 流畅
    standardImgUrl: require('../assets/index/biaoqing.png'), //视频清晰度 标清
    highImgUrl: require('../assets/index/gaoqing.png'), //视频清晰度 高清
    ultraclearImgUrl: require('../assets/index/1080p.png'), //视频清晰度 超清
    trackPlayback: require('../assets/channelSessionMenu/icon_route_nor2x.png'), //轨迹回放
    expandUrl:require('../assets/index/expand.png'), //展开菜单
    retractUrl:require('../assets/index/retract.png'), //收起菜单
    suoxiaoUrl:require('../assets/index/suoxiao.png'), //收起开关机记录
    fangdaUrl:require('../assets/index/fangda.png'), //展开开关机记录
    detailsUrl:require('../assets/index/xiangqing.png'), //用户详情
    /**
     * 山东警保视频监控列表
     * "indexCode": "1a28c630637a4714b73b9dd212b57c5b",
     * "regionIndexCode": "472d79ecc9ef4aeca425374defd66e73",
     * "name": "四号楼一楼西",
     * "parentIndexCode": "a95ee34f91924bed92b9f1d0ed2d1bcf",
     * "longitude": "117.733432",
     * "latitude": "36.236020",
     * "cameraType": 0,
     * "installLocation": "",
     * "chanNum": 1,
     * "dacIndexCode": "--",
     * "capability": "@event_vss@io@vss@record@event_rule@remote_vss@maintenance@event_device@status@",
     * "channelType": "digital",
     * "decodeTag": "hikvision",
     * "resourceType": "camera",
     * "createTime": "2022-06-09T20:26:56.324+08:00",
     * "updateTime": "2023-06-10T11:24:15.117+08:00",
     * "sort": 6063,
     * "disOrder": 6063,
     * "cameraRelateTalk": "89c0e18679c24efd873590f5225cff01",
     * "transType": 1,
     * "treatyType": "ehome_reg",
     * "cascadeType": 0,
     * "regionName": "高新区实验学校",
     * "regionPath": "@root000000@6e08f9648b7f42d789993d09e26dbb67@472d79ecc9ef4aeca425374defd66e73@",
     * "regionPathName": "莱芜区/莱芜区学校/高新区实验学校"
     */
    policeCameraList:[],
  },
  // set方法
  mutations: {
    setTokenInvalid(state, invalid) {
      state.isTokenInvalid = invalid
    },
    setConfig(state, config) {
      state.config = config
    },
    setBaseUrl(state, baseUrl) {
      state.baseUrl = baseUrl
    },
    setToken(state, token) {
      state.token = token
    },

    setTrackLocationHash(state, trackLocationHash) {
      state.trackLocationHash = trackLocationHash
    },

    setShowOnMap(state, showOnMap) {
      state.showOnMap = showOnMap
    },
    updateFollowLocUidList(state, obj) {
      if(obj.type=='add'){
        state.followLocUidList[obj.uid]=obj.uid
        state.allUserList[obj.uid].isFollowLoc=true
      }else if(obj.type=='delete'){
        state.followLocUidList[obj.uid]=null
        state.allUserList[obj.uid].isFollowLoc=false
        VueEvent.eventBus.$emit(VueEvent.EVT_CLEAR_ONE_TRACE_USER, obj.uid)
      }
    },

    setSpeakerId(state, speakerId) {
      state.speakerId = speakerId
    },

    setMyUserId(state, uid) {
      state.myUserId = uid
    },

    setMyTeamId(state, teamId) {
      state.myTeamId = teamId
    },

    setMyTeamName(state, teamName) {
      state.myTeamName = teamName
    },

    /**
     * @param obj
     * {uid: {Number} 用户id, puller: {Number} 发起人uid, url: {String} 观看推流的url }
    */
    updateUserRealVideo(state, obj) {
      console.log('updateUserRealVideo old pullBy =' + state.allUserList[obj.uid].pullBy)
      console.log('updateUserRealVideo old realUrl =' + state.allUserList[obj.uid].realUrl)

      state.allUserList[obj.uid].pullBy = obj.puller
      state.allUserList[obj.uid].realUrl = obj.url

      console.log('updateUserRealVideo ' + state.allUserList[obj.uid].name)
    },
    /**
     * 更新消息助手未读消息展示状态
     * @param {boolean} val
     */
    updateMessageTips(state, val) {
      state.messageTipsShow = val
    },
    /**
     * 退出登录时将获取信息的状态全部重置为false
     */
    updateInformation(state) {
      state.informationCompletion.userApiCompletion = false
      state.informationCompletion.orgApiCompletion = false
      state.informationCompletion.fenceApiCompletion = false
      state.informationCompletion.groupApiCompletion = false
      state.informationCompletion.taggingApiCompletion = false
      state.informationCompletion.beaconApiCompletion = false
      state.informationCompletion.factoryApiCompletion = false
      state.informationCompletion.locationBeaconApiCompletion = false
      state.informationCompletion.samplePointApiCompletion = false
    },

    /** 刷新所有基础数据
     * */
    refreshAllBaseInfo(state) {
      window.vue.$store.commit('refreshTeamUsers')
      window.vue.$store.commit('refreshAllGroups')
      window.vue.$store.commit('refreshAllFences')
      window.vue.$store.commit('refreshAllRegions')
      window.vue.$store.commit('refreshAllTaggings')
      window.vue.$store.commit('refreshAllBeacons')
      if(state.config.mapVersion=='BMapGL'){
        window.vue.$store.commit('refreshFactory')
        window.vue.$store.commit('refreshLocationBeacon')
        window.vue.$store.commit('refreshSamplePoint')
      }
      if(state.config.brand=='sdjb'){
        window.vue.$store.commit('refreshCameraList')

      }
    },

    refreshCameraList(state){
      axios.get('https://api.quanyong.mobi:8444/unipttadmin/camera/search?pageNo=1&pageSize=1000').then(rsp => {
        if(rsp.data.data&&rsp.data.data.list){
          state.policeCameraList=rsp.data.data.list
        }
        state.informationCompletion.policeCameraApiCompletion=true
      }).catch(e => {
        console.log('监控列表错误',e)
        window.vue.$message.warning('监控列表错误')
      })
    },


    /**
     *从后端重新请求所有围栏数据
     *
     * @param {*} state
     */
    refreshAllFences(state) {
      window.vue.$api.getFencesWithPoints().then((res) => {
        state.allFenceList = []

        for (let fence of res.list) {
          fence.color = fence.color === 0 ? '#1791fc' : fence.color
          window.vue.$set(state.allFenceList, fence.id, fence)
        }
        state.informationCompletion.fenceApiCompletion = true

        VueEvent.eventBus.$emit(VueEvent.EVT_FENCE_LIST_REFRESH)
      })
    },
    // 删除围栏后，将删除的围栏重置为null
    updateFence(state, fenceId) {
      window.vue.$set(state.allFenceList, fenceId, null)
    },

    /**
     * 从后端获取所有区域划分数据
     * @param state
     */
    refreshAllRegions(state) {
      window.vue.$api.annotationRegion().then((res) => {
        res.extend.annotationRegions.forEach((item) => {
          window.vue.$api.annotationRegionId({ id: item.id }).then((resp) => {
            let datainfo = {
              id: item.id,
              name: item.name,
              Color: item.color == 0 ? '#1791fc' : item.color,
              Locations: resp.extend.annotationRegiondetail.lngLats,
            }
            window.vue.$set(state.allRegionList, item.id, datainfo)
          })
        })
      })
    },

    /**
     * 删除区域后，将删除的区域重置为null
     * @param id
     */
    updateRegion(state,id){
      window.vue.$set(state.allRegionList, id, null)
    },

    buildOrgTreeMap(state, param) {
      let org = param.org
      let arr = param.arr

      state.subOrgs.push(org.id)

      let arr1 = []
      for (let i = 0; i < arr.length; i++) {
        arr1[i] = arr[i]
      }
      arr1[arr.length] = org.id
      state.orgTreeMap.set(org.id, arr1)

      for (let child of org.children) {
        let param1 = {
          org: child,
          arr: arr1,
        }
        window.vue.$store.commit('buildOrgTreeMap', param1)
      }
    },

    /**
     *刷新集团下所有用户列表
     *
     * @param {*} state
     */
    refreshTeamUsers(state) {
      state.allUserList.splice(0)
      // 获取集团下所有用户
      window.vue.$api.getAllTeamUsers().then((json) => {
        // 获取平级和下级组织结构下的用户
        window.vue.$api.getUsersInchargeOf().then((res) => {
          if(state.allUserList.length>1){
            for (let uid in state.allUserList) {
              // 临时增加的属性 toDelete,用于差分标记
              state.allUserList[uid].toDelete = true
            }
          }


          let selfId = Number(state.myUserId)
          let arr = json.users
          for (let i in arr) {
            if (selfId === arr[i].id) {
              // 当前登录用户姓名和所属公司
              state.myUserInfoText = arr[i].name + '(' + state.myTeamName + ')'
            }

            let user = state.allUserList[arr[i].id]
            if (!user) {
              user = {
                uid: null,
                name: null,
                pinyin: null,
                monitor: null, // 是否添加到地图上显示
                locPermission: null, // 是否有定位权限
                talkStatus: null, // 是否在线（卫星电话用是否有定位作为是否在线）
                online: null, // 是否在线
                curGroupId: null, //该用户当前守护组
                inChargeOf: null, // 临时将所有用户标记为不属于当前调度员管辖
                toDelete: null, //临时属性标志，用于差分
                realUrl: null, // 正在推流的url, null代表未在推流
                pullBy: null, //拉起终端视频推流的用户id
                mdn: null,
                liveVideo: null, //是否开启了视频套餐
                orgId: null,
                icon: null,
                comment: null,
                locationInfo: null,
                outdoor: false,
                teamName:null,
                orgName:null,
                teamId:null,
                isFollowLoc:false,
                factoryId:null,
                buildingId:null,
                floorId:null,
                tbateryLevel:null,
                powerOn:null,
                satellitePhone:false,
                imei:null
              }
              window.vue.$set(state.allUserList, arr[i].id, user)
            }

            Object.assign(user, {
              uid: arr[i].id,
              name: arr[i].name + (selfId === arr[i].id ? '(本机)' : ''),
              pinyin: arr[i].pinyin,
              monitor: true, // 是否添加到地图上显示
              locPermission: true, // 是否有定位权限
              talkStatus: false, // 是否在线（卫星电话用是否有定位作为是否在线）
              online: arr[i].online, // 是否在线
              curGroupId: arr[i].joinedGroupId, //该用户当前守护组
              inChargeOf: false, // 临时将所有用户标记为不属于当前调度员管辖
              toDelete: false, //临时属性标志，用于差分
              realUrl: null, // 正在推流的url, null 代表未在推流
              pullBy: null, //拉起终端视频推流的用户id
              mdn: '',
              liveVideo: false, ////是否开启了视频套餐
              orgId: 0,
              teamName:null,
              orgName:null,
              teamId:null,
              isFollowLoc:state.followLocUidList[arr[i].id]?true:false,
              factoryId:null,
              buildingId:null,
              floorId:null,
              batteryLevel:null,
              powerOn:arr[i].powerOn,
              satellitePhone:false,
              imei:null
            })
          }

          // 删除被删除的用户
          for (let uid in state.allUserList) {
            if (state.allUserList[uid] && state.allUserList[uid].toDelete) {
              window.vue.$set(state.allUserList, uid, null)
            }
          }

          for (let inchargeUser of res.list) {
            if (selfId === inchargeUser.uid) {
              state.myOrgId = inchargeUser.orgId == null ? 0 : inchargeUser.orgId
            }
            let u = state.allUserList[inchargeUser.uid]
            if (!u) {
              continue
            }
            u.teamName=inchargeUser.teamName?inchargeUser.teamName:''
            u.orgName=inchargeUser.orgName?inchargeUser.orgName:''
            u.teamId=inchargeUser.teamId
            u.inChargeOf = true
            u.icon = inchargeUser.icon
            u.realUrl = inchargeUser.realUrl // 正在推流的url, null 代表未在推流
            u.pullBy = inchargeUser.pullBy //拉起终端视频推流的用户id
            u.mdn = inchargeUser.mdn
            u.comment = inchargeUser.comment
            u.liveVideo = inchargeUser.liveVideo //是否开启了视频套餐
            u.orgId = inchargeUser.orgId
            u.factoryId=inchargeUser.factoryId
            u.buildingId=inchargeUser.buildingId
            u.floorId=inchargeUser.floorId
            u.batteryLevel=inchargeUser.batteryLevel
            u.imei=inchargeUser.imei
            if(u.mdn&&(u.mdn.substring(0,4)=='1740' ||u.mdn.substring(0,7)=='+861740'||u.mdn.substring(0,6)=='001740'
                ||u.mdn.indexOf('15318707585')>=0||u.mdn.indexOf('18953269925')>=0 ||u.mdn.indexOf('13370830892')>=0||u.mdn.indexOf('13310663544')>=0)){
              u.satellitePhone=true
            }
            if (inchargeUser.longitude || inchargeUser.latitude) {
              u.locationInfo = {
                time: new Date(inchargeUser.time),
                lng: inchargeUser.longitude,
                lat: inchargeUser.latitude,
                altitude: inchargeUser.altitude,
                velocity: inchargeUser.velocity,
                heading: inchargeUser.heading,
                locAccuracy: inchargeUser.locAccuracy,
                locMode: inchargeUser.locMode,
                locSpan: inchargeUser.locSpan,
                lngBd09: inchargeUser.lngBd09,
                latBd09: inchargeUser.latBd09,
                lngGcj: inchargeUser.lngGcj,
                latGcj: inchargeUser.latGcj,
              }
              u.outdoor = inchargeUser.locAccuracy > 0 && inchargeUser.locAccuracy < 200
            } else {
              u.locationInfo = null
              u.outdoor = false
            }
          }

          state.informationCompletion.userApiCompletion = true
          // 计算在线离线人数
          window.vue.$store.commit('statisUserStatus')
          // 处理完所有用户信息后 在地图渲染用户定位信息
          VueEvent.eventBus.$emit(VueEvent.EVT_USER_LIST_REFRESH)

          window.vue.$api.getOrgsFlat({teamId:state.myTeamId,orgType:0}).then(rsp=>{
            console.log('扁平化组织结构',rsp)
            state.orgsFlat=[]
            if(rsp.orgList&&rsp.orgList.length>0){
              rsp.orgList.forEach(item=>{
                state.orgsFlat[item.id]=item
              })
            }
          })

          let param = {
            orgId: state.myOrgId,
            teamId: state.myTeamId,
          }
          // 获取组织结构树
          window.vue.$api.getOrgTree(param).then((rsp) => {
            state.orgTree = rsp.extend.orgs
            state.subOrgs = []
            state.orgTreeMap = new Map()
            if (rsp.extend.orgs.id == null) {
              rsp.orgs.id = 0
            }

            let param1 = {
              org: rsp.extend.orgs,
              arr: [],
            }
            window.vue.$store.commit('buildOrgTreeMap', param1)
            state.informationCompletion.orgApiCompletion = true
            // 组织结构树可能发生变化，重新订阅用户定位数据变化通知
            Mqtt.reSubscribeUserLocation()
            console.log('所有用户信息',state.allUserList)
          })
        })
      })

    },

    /**
     * 计算在线离线用户数量
     * @param state
     */
    statisUserStatus(state) {
      state.onlineCount = 0
      state.inroomCount = 0
      state.outdoorCount = 0
      state.offlineCount = 0
      for (let i in state.allUserList) {
        let userInfo = state.allUserList[i]
        if (userInfo && userInfo.inChargeOf && userInfo.locationInfo) {
          if (userInfo.online) {
            state.onlineCount++
            if (userInfo.locationInfo.locAccuracy < 200 && userInfo.locationInfo.locAccuracy > 0) {
              state.outdoorCount++
            } else {
              state.inroomCount++
            }
          } else {
            state.offlineCount++
          }
        }
      }
    },
    /**
     * 获取用户所在的所有群组
     */
    refreshAllGroups(state) {
      let param = {
        page: 0,
        teamId: state.myTeamId,
      }
      window.vue.$api.teamAllGroup(param).then((json) => {
        state.allGroupList.splice(0)
        json.extend.group.forEach((val, index) => {
          // let group= {
          //   id: val.id,
          //   name: val.name
          //   groupType：{Number} 1=预定义组 2= 临时组
          // priority： {Number} 优先级
          // userList: {Number}[] 成员uid列表
          // guardCount: {Number} 在线守护本组的人数
          // onlineCount: {Number} 本组在线的人数
          // }
          val.guardCount = 0
          val.onlineCount = 0
          window.vue.$set(state.allGroupList, val.id, val)
          window.vue.$api.memberStatus({ groupId: val.id }).then((res) => {
            let group = val
            let guardCount = 0 //守护
            let onlineCount = 0 //在线
            group.userList = []
            res.statusList.forEach((item) => {
              group.userList.push(item.uid)
              if (item.online) {
                onlineCount++
                if (item.joined) guardCount++
              }
            })
            group.guardCount = guardCount
            group.onlineCount = onlineCount
          })
        })
        state.informationCompletion.groupApiCompletion = true
        window.vue.$store.commit('delInvalidRecentGroupList')
      })
    },
    /**
     * 获取所有标注信息
     * @param state
     */
    refreshAllTaggings(state) {
      window.vue.$api.getAnnotationList().then((rsp) => {
        state.allTaggingList = rsp.list
        state.informationCompletion.taggingApiCompletion = true
        VueEvent.eventBus.$emit(VueEvent.EVT_TAG_LIST_REFRESH)
      })
    },

    /**
     * 获取所有巡更点信息
     * @param state
     */
    refreshAllBeacons(state) {
      window.vue.$api.getBeacons().then((rsp) => {
        state.allBeaconList = rsp.extend.beacons
        state.informationCompletion.beaconApiCompletion = true
        VueEvent.eventBus.$emit(VueEvent.EVT_BEACON_LIST_REFRESH)
      })
    },
    /**
     * 获取厂区信息和建筑物信息
     * @param state
     */
    refreshFactory(state){
      let factoryLength=0
      let buildingLength=0
      window.vue.$api.factory().then(rsp=>{
        if(rsp.extend.factoryList.length>0){
          rsp.extend.factoryList.forEach(factory=>{
            window.vue.$set(state.allFactoryList, factory.id, factory)
            window.vue.$api.building({factoryId:factory.id}).then(res=>{
              factoryLength++
              if(res.list.length>0){
                res.list.forEach(building=>{
                  window.vue.$set(state.allBuildingList, building.id, building)
                  if(factoryLength==rsp.extend.factoryList.length){
                    buildingLength++
                    if(buildingLength==res.list.length){
                      state.informationCompletion.factoryApiCompletion = true
                      setTimeout(()=>{
                        VueEvent.eventBus.$emit(VueEvent.EVT_ADD_FACTORY_BUILDING)
                      },2000)
                    }
                  }
                })
              }else{
                setTimeout(()=>{
                  VueEvent.eventBus.$emit(VueEvent.EVT_ADD_FACTORY_BUILDING)
                },2000)
                state.informationCompletion.factoryApiCompletion = true
              }
            })
          })
        }else{
          state.informationCompletion.factoryApiCompletion = true
        }
      })
    },
    /**
     * 获取所有定位信标信息
     * @param state
     */
    refreshLocationBeacon(state){
      state.allLocationBeaconList.splice(0)
      window.vue.$api.locationBeacon().then((rsp) => {
        state.allLocationBeaconList=rsp.list
        state.informationCompletion.locationBeaconApiCompletion = true
        VueEvent.eventBus.$emit(VueEvent.EVT_LOCATION_BEACON_LIST_REFRESH)
      })
    },
    /**
     * 获取所有预采点信息
     * @param state
     */
    refreshSamplePoint(state){
      state.allSamplePointList.splice(0)
      window.vue.$api.samplePoint().then((rsp) => {
        rsp.list.forEach(item=>{
          window.vue.$set(state.allSamplePointList, item.id, item)
        })
        state.informationCompletion.samplePointApiCompletion = true
        VueEvent.eventBus.$emit(VueEvent.EVT_SAMPLE_POINT_LIST_REFRESH)
      })
    },

    /**
     * 通过mq消息直接更新用户状态
     * @param  {UserStatus}[]msg
     *  UserStatus {
     *   uid:  {Number},
     *   online: {Boolean},
     *   groupId: {Number},
     *   groupType: {Number}
     *  }
     * */
    updateUserStatus(state,msg){
      for (const e of msg) {
        let u = state.allUserList[e.uid]
        if (!u) {
          console.log('updateUserStatus error, user not found, uid=' + e.uid)
          continue
        }

        if (e.groupId > 0) {
          // 用户守护了一个调度台看不到的其它群组
          if (!state.allGroupList[e.groupId]) {
            console.warn('updateUserStatus error, group not found, groupId=' + e.groupId)
            e.groupId = 0
          }
        }
        // 在线状态改变
        if (u.online !== e.online) {
          let groups = window.vue.$store.getters.getGroupsOfUser(e.uid)
          if (!u.online) {
            u.online = e.online
            // 离线变为在线
            if(groups){
              for (let group of groups) {
                group.onlineCount++
              }
            }
            if (e.groupId > 0) {
              // 不为空不为0
              state.allGroupList[e.groupId].guardCount++
            }
            u.curGroupId = e.groupId
            if (u.inChargeOf&&u.locationInfo) {
              state.offlineCount--
              state.onlineCount++
              if (u.outdoor) {
                state.outdoorCount++
              } else {
                state.inroomCount++
              }
              VueEvent.eventBus.$emit(VueEvent.EVT_USER_ONLINE_CHANGED, e)
            }
          } else {
            u.online = e.online
            // 在线变为离线
            if(groups){
              for (let group of groups) {
                group.onlineCount--
              }
            }

            if (u.curGroupId) {
              state.allGroupList[u.curGroupId].guardCount--
              u.curGroupId = null
            }

            if (u.inChargeOf&&u.locationInfo) {
              state.onlineCount--
              state.offlineCount++
              if (u.outdoor) {
                state.outdoorCount--
              } else {
                state.inroomCount--
              }
              VueEvent.eventBus.$emit(VueEvent.EVT_USER_ONLINE_CHANGED, e)
            }
          }

          // 若卫星电话离线 则从接口获取卫星电话开关机状态
          if(u.satellitePhone&&u.online&&!e.online){
            window.vue.$api.isSatelOnline({uid:u.uid}).then(rsp=>{
              u.powerOn=rsp.extend.online
              // 在线状态发生了变化 需要更新地图人员显示图标
              VueEvent.eventBus.$emit(VueEvent.EVT_USER_LOCATION_CHANGED, u.uid)
            })
          }else{
            // 在线状态发生了变化 需要更新地图人员显示图标
            VueEvent.eventBus.$emit(VueEvent.EVT_USER_LOCATION_CHANGED, u.uid)
          }


        } else if (u.curGroupId !== e.groupId && e.online) {
          // 在线状态未改变，之前之后都是在线状态，只有在组状态改变
          if (u.curGroupId > 0 && e.groupId > 0) {
            //undefine null和 0 均不满足条件
            // 换组，A->B
            if(state.allGroupList[u.curGroupId]){
              state.allGroupList[u.curGroupId].guardCount--
            }
            if(state.allGroupList[e.groupId]){
              state.allGroupList[e.groupId].guardCount++
            }
          } else if (u.curGroupId > 0 && !e.groupId) {
            // A -> 0
            if(state.allGroupList[u.curGroupId]){
              state.allGroupList[u.curGroupId].guardCount--
            }
          } else if (!u.curGroupId && e.groupId > 0) {
            // 0 ->A
            // 在线状态没变 守护组肯定是在线的
            if(state.allGroupList[e.groupId]){
              state.allGroupList[e.groupId].guardCount++
            }
          } else {
            // 0->null, null->0 , do nothing
          }
          u.curGroupId = e.groupId
        }
      }
    },

    /**
     * 通过mq消息更新用户的定位信息
     * msg: Object 字段
     * uid: {Number} 用户id
     * time: {Date} 定位时间
     * longitude: {Number}经度
     * latitude：{Number}纬度
     * lngBd09: {Number}百度经度
     * latBd09: {Number}百度纬度
     * lngGcj: {Number}国测局经度,
     * latGcj: {Number} 国测局纬度
     * altitude：{Number} 海拔高度（单位米）
     * velocity：{Number} 速度（单位米/秒）
     * heading: {Number} 朝向 (单位角度，0~360, 正北为0度，顺时针)
     * locAccuracy：{Number} 精度，单位米
     * locMode：{Number} 定位模式， 3= GPS， 4=网络定位
     * locSpan：{Number} 定位间隔，单位秒
     * factoryId：{Number} 厂区id
     * buildingId：{Number} 建筑物id
     * floorId：{Number} 楼层id
     * */
    updateUserLocation(state, msg) {
      let u = state.allUserList[msg.uid]
      if (!u || !u.inChargeOf) {
        return
      }

      let oldOutDoor = u.outdoor
      let oldLocationInfo = u.locationInfo
      let oldBuildingId=u.buildingId
      let oldFloorId=u.floorId
      u.locationInfo = {
        time:new Date(Utils.timestampToTime(msg.time)),
        lng: msg.longitude,
        lat: msg.latitude,
        lngBd09: msg.lngBd09,
        latBd09: msg.latBd09,
        lngGcj: msg.lngGcj,
        latGcj: msg.latGcj,
        altitude: msg.altitude,
        velocity: msg.velocity,
        heading: msg.heading,
        locAccuracy: msg.locAccuracy,
        locMode: msg.locMode,
        locSpan: msg.locSpan,
      }

      u.factoryId=msg.factoryId?msg.factoryId:null
      u.buildingId=msg.buildingId?msg.buildingId:null
      u.floorId=msg.floorId?msg.floorId:null
      u.batteryLevel=msg.batteryLevel?msg.batteryLevel:null

      u.outdoor = msg.locAccuracy > 0 && msg.locAccuracy <= 200
      if (u.online && oldOutDoor != u.outdoor) {
        if (oldOutDoor) {
          state.outdoorCount--
          state.inroomCount++
        } else {
          state.outdoorCount++
          state.inroomCount--
        }
      }
      if(u.online && !oldLocationInfo){
        if (u.locationInfo.locAccuracy < 200 && u.locationInfo.locAccuracy > 0) {
          state.outdoorCount++
        } else {
          state.inroomCount++
        }
      }

      if(u.satellitePhone&&!u.online&&!u.powerOn){
        u.powerOn=true
      }

      // 此用户被定位跟踪
      if(msg.uid==state.followLocUidList[msg.uid]){
        VueEvent.eventBus.$emit(VueEvent.EVT_MAP_TRACE_USERS, {uid:msg.uid,locationInfo:oldLocationInfo,buildingId:oldBuildingId,floorId:oldFloorId})
      }

      VueEvent.eventBus.$emit(VueEvent.EVT_USER_LOCATION_CHANGED, msg.uid)
    },
    /**
     * 卫星电话开关机状态更新
     * @param state
     * @param msg
     */
    updatePowerStatus(state, msg) {
      let u = state.allUserList[msg.uid]
      if (!u || !u.inChargeOf) {
        return
      }
      let oldPowerOn=u.powerOn
      u.powerOn=msg.powerOn
      u.batteryLevel=msg.batteryLevel
      if(oldPowerOn!=msg.powerOn&&!u.online){
        // 开关机状态发生了变化 需要更新地图人员显示图标
        VueEvent.eventBus.$emit(VueEvent.EVT_USER_LOCATION_CHANGED, msg.uid)
      }
    },

    /**
     * 创建单呼临时组,
     * @param {Object} obj
     * {
     *  uidList: {number}[] 用户id集合
     *  name: {string} 临时组名称
     * }
     * @return 无
     * */
    createAdhocAndMakeCall(state, obj) {
      let datainfo = {
        name: obj.name
          ? obj.name
          : state.allUserList[state.myUserId].name + ' ' + Utils.dateToDateTimeString(new Date()),
        userList: obj.uidList,
      }
      if(obj.name){
        let name=obj.name
        let num=name.indexOf('(本机)')
        if(num>=0){
          obj.name=name.substring(0,num)+name.substring(num+4,name.length)
        }
      }else{
        let name=state.allUserList[state.myUserId].name + ' ' + Utils.dateToDateTimeString(new Date())
        let num=name.indexOf('(本机)')
        if(num>=0){
          obj.name=name.substring(0,num)+name.substring(num+4,name.length)
        }
      }
      // 创建临时组
      window.vue.$api.createAdhoc(datainfo).then((res) => {
        let groupid = res.extend.group.id
        let val = {
          id: groupid,
          name: res.extend.group.name,
          groupType: 2,
          orgId: res.extend.group.orgId,
          orgName: res.extend.group.orgName,
          priority: res.extend.group.priority,
          type: res.extend.group.type,
          showopertion: false,
          showuser: false,
          guardCount: 0,
          onlineCount: 0,
          userlist: obj.uidList,
          monitor: false,
        }
        let onlineCount = 0 //在线
        for (let uid of obj.uidList) {
          let u = state.allUserList[uid]
          if (u && u.online) {
            onlineCount++
          }
        }
        val.onlineCount = onlineCount
        window.vue.$set(state.allGroupList, groupid, val)
        setTimeout(() => {
          // 通过插件守护组 并讲话
          voiceWebsocket.pregroupCall(groupid)
        }, 500)
      })
    },
    /**
     * 修改最近对讲群组
     * @param {number} groupId 群組id
     */
    updateRecentGroupList(state, groupId) {
      if (state.recentGroupList.length > 0) {
        let adhocSelectIndex = -1
        state.recentGroupList.forEach((item, index) => {
          if (item.id == groupId) {
            adhocSelectIndex = index
          }
        })
        if (adhocSelectIndex < 0 && state.recentGroupList.length < 10) {
          //该预定义组从未对讲过且对讲过的预定义组不超过10个
          state.recentGroupList.unshift({ id: groupId, name: state.allGroupList[groupId].name })
        } else if (adhocSelectIndex < 0 && state.recentGroupList.length == 10) {
          //该预定义组从未对讲过且对讲过的预定义组超过10个
          state.recentGroupList.splice(9, 1) //删除最后一个
          state.recentGroupList.unshift({ id: groupId, name: state.allGroupList[groupId].name })
        } else if (adhocSelectIndex >= 0) {
          //该预定义组对讲过
          state.recentGroupList.splice(adhocSelectIndex, 1)
          state.recentGroupList.unshift({ id: groupId, name: state.allGroupList[groupId].name })
        }
      } else {
        state.recentGroupList.unshift({ id: groupId, name: state.allGroupList[groupId].name })
      }
    },

    /**
     * 群组信息更新后，查询最近对讲群组的群组信息是否有效，无效则删除
     */
    delInvalidRecentGroupList(state){
      if(state.recentGroupList.length>0){
        let adhocIndexList=[]
        state.recentGroupList.forEach((item,index)=>{
          if(!state.allGroupList[item.id]){
            adhocIndexList.unshift(index)
          }
        })
        if(adhocIndexList.length>0){
          adhocIndexList.forEach(adhocIndex=>{
            state.recentGroupList.splice(adhocIndex,1)
          })
        }
      }
    },

    /**
     * 更新语音服务器登录提示展示状态
     * @param {boolean} val
     */
    updateShowTips(state, val) {
      state.showTips = val
    },
    /**
     * 更新语音服务器登录提示展示文字
     * @param {string} msg
     */
    updateLoginStates(state, msg) {
      state.loginStates = msg
    },
  },
  // get方法
  getters: {
    getConfig(state) {
      return state.config
    },
    getBaseUrl(state) {
      return state.baseUrl
    },
    getToken(state) {
      return state.token
    },
    getAllUserList(state) {
      return state.allUserList
    },

    getAllFenceList(state) {
      return state.allFenceList
    },

    getAllBeaconList(state) {
      return state.allBeaconList
    },

    getTrackLocationHash(state) {
      return state.trackLocationHash
    },
    getAllGroupList(state) {
      return state.allGroupList
    },
    getShowOnMap(state) {
      return state.showOnMap
    },
    getFollowLocUidList(state) {
      return state.followLocUidList
    },

    getSpeakerId(state) {
      return state.speakerId
    },

    getMyUserId(state) {
      return state.myUserId
    },

    getMyTeamId(state) {
      return state.myTeamId
    },

    getMyTeamName(state) {
      return state.myTeamName
    },

    getMyUserInfoText(state) {
      return state.myUserInfoText
    },
    getTaggingById: (state) => (id) => {
      for (let i in state.allTaggingList) {
        let item = state.allTaggingList[i]
        if (item.id === id) {
          return item
        }
      }
    },
    getBeaconById: (state) => (id) => {
      for (let i in state.allBeaconList) {
        let item = state.allBeaconList[i]
        if (item.beaconId == id) {
          return item
        }
      }
      return null
    },
    getBeaconIndexById: (state) => (id) => {
      for (let i in state.allBeaconList) {
        let item = state.allBeaconList[i]
        if (item.beaconId == id) {
          return i
        }
      }
      return null
    },

    getAnnotationById: (state) => (id) => {
      for (let i in state.allTaggingList) {
        if (state.allTaggingList[i].id === id) {
          return state.allTaggingList[i]
        }
      }
      return null
    },

    /**
     * 获取用户属于的群组列表
     * @param  {Number} uid
     * @return {Group}[] Group是 allGroupList里对象的引用
     * */
    getGroupsOfUser: (state) => (uid) => {
      let groups = []
      for (let i in state.allGroupList) {
        let group = state.allGroupList[i]
        if (!group) {
          continue
        }
        for (let uid1 of group.userList) {
          if (uid === uid1) {
            groups.push(group)
            break
          }
        }
      }
      return groups
    },

  },
  actions: {},
  modules: {},
})
