import Stomp from 'stompjs'
import VueEvent from '../js/common/VueEvent.js'

var mqttClient = null //MQ连接实例

/**
 * 所有订阅的topic
 * [
 *  {
 *    {string} topic // 主题
 *    {callback} cb  // 回调
 *    {callback} onSubscribeFailed 订阅失败回调
 *    {Subscription} subscription 订阅者。用于取消订阅
 *  },
 * ]
 *
 */
var topicList = [] //MQ动态订阅的topic
var mqttConnected = false // 标记当前状态是否已经连接成功
var reconnectTimer = null // 重连mqtt的定时器

var sosUidList = [] // 山东警保记录用户是否已经报警，报警后三秒内的视频推流不提示调度台观看


// 订阅topic
function subscribeTopic(topicName, cb) {
  let obj
  for (obj of topicList) {
    if (obj.topic === topicName) {
      console.log('重复订阅Topic:' + topicName)
      return
    }
  }

  // mqtt库有个问题，连续订阅不能用同一个订阅失败回调，因此每个订阅单独定义一个回调函数
  obj = {
    topic: topicName,
    cb: cb,
    onSubscribeFailed: function (msg) {
      console.log('MQ订阅失败:', msg.body)
      if (mqttConnected) {
        // 重新订阅
        resubscribe(topicName)
      }
    },
  }
  topicList.push(obj)

  // 如果mqtt已经连接，需要马上订阅
  if (mqttConnected) {
    console.log('订阅topic: ' + obj.topic)
    obj.subscription = mqttClient.subscribe(obj.topic, obj.cb, obj.onSubscribeFailed)
  }
}

// topic订阅失败后重新订阅
function resubscribe(topicName) {
  if (!mqttConnected) {
    return
  }
  for (let obj of topicList) {
    if (obj.topic === topicName) {
      obj.subscription = mqttClient.subscribe(topicName, obj.cb, obj.onSubFailedUserNotify)
      return
    }
  }
}

// 取消订阅topic
function unsubscribeTopic(topicName) {
  let i = 0
  let obj = null
  for (i = 0; i < topicList.length; i++) {
    if (topicList[i].topic === topicName) {
      obj = topicList[i]
      break
    }
  }

  if (obj) {
    if (mqttConnected && obj.subscription) {
      obj.subscription.unsubscribe()
    }
    topicList.splice(i, 1)
  }
}

// 初始化topicList
function initMqtt() {
  topicList = []
  let teamId = window.vue.$store.getters.getMyTeamId

  // 订阅围栏变化通知
  let topic = '/topic/teamFenceUpdate_' + teamId
  subscribeTopic(topic, onMqTeamFenceUpdate)

  // 订阅标注点变化通知
  topic = '/topic/teamAnnotationUpdate_' + teamId
  subscribeTopic(topic, onMqTeamAnnotationUpdate)

  // 订阅巡更点变化通知
  topic = '/topic/teamBeaconUpdate_' + teamId
  subscribeTopic(topic, onMqTeamBeaconUpdate)

  //订阅集团组织结构更新通知的topic
  topic = '/topic/teamOrgUpdate_' + teamId
  subscribeTopic(topic, onMqTeamOrgUpdate)

  //订阅集团群组更新通知的topic
  topic = '/topic/teamGroupUpdate_' + teamId
  subscribeTopic(topic, onMqTeamGroupUpdate)

  //订阅集团成员更新通知的topic
  topic = '/topic/teamUserUpdate_' + teamId
  subscribeTopic(topic, onMqTeamUserUpdate)

  //订阅用户在线在组变化通知的topic
  topic = '/topic/team_user_status_' + teamId
  subscribeTopic(topic, onMqTeamUserStatusUpdate)

  //订阅视频推流通知、停止推流通知、事件通知、请假通知、会议通知的topic
  topic = '/topic/user_notify_' + window.vue.$store.getters.getMyUserId
  subscribeTopic(topic, onMqUserNotify)

  //订阅任务、事件附件上传通知的topic
  topic = '/topic/team_attachment_' + teamId
  subscribeTopic(topic, onMqEnclosure)

  //订阅SOS报警通知的topic
  topic = '/topic/team_sos_' + teamId
  subscribeTopic(topic, onMqTeamSos)

  //订阅卫星电话开关机通知的topic
  topic = '/topic/team_user_power_status_' + teamId
  subscribeTopic(topic, onMqPowerStatus)

  if(teamId==1){
    window.vue.$api.securityTeamOrg().then(rsp=>{
      if(rsp.list&&rsp.list.length>0){
        rsp.list.forEach(item=>{
          let topic = '/topic/team_user_status_' + item.teamId
          subscribeTopic(topic, onMqTeamUserStatusUpdate)
          topic = '/topic/teamUserUpdate_' + item.teamId
          subscribeTopic(topic, onMqTeamUserUpdate)
          topic = '/topic/team_sos_' + item.teamId
          subscribeTopic(topic, onMqTeamSos)
          item.orgIds.forEach(orgId=>{
            topic= '/topic/user_location_update_'+item.teamId+'_'+orgId
            subscribeTopic(topic, onMqUserLocationUpdate)
          })
        })
      }
      //连接MQ
      connect()
    })
  }else{
    //连接MQ
    connect()
  }
}

// 连接MQ
function connect() {
  let headers = {
    login: window.vue.$store.getters.getConfig.mq_login,
    passcode: window.vue.$store.getters.getConfig.mq_passcode,
  }
  mqttClient = Stomp.client(window.vue.$store.getters.getConfig.mq_http)
  // 关闭控制台调试数据
  mqttClient.debug = null
  // 控制台调试数据
  console.log('开始连接MQ')
  mqttClient.connect(headers, onConnected, onFailed)
  // 心跳间隔
  mqttClient.heartbeatOutgoing = 3000
  // 接受两次心跳的最小间隔
  mqttClient.heartbeatIncoming = 3000
  // 重新连接延迟时间
  mqttClient.reconnectDelay = 500
  mqttClient.onStompError = function (e) {
    mqttConnected = false
    console.log('MQ服务连接异常，正在重新连接:', e)
    // 连接错误后重新连接
    if (reconnectTimer) {
      clearTimeout(reconnectTimer)
    }
    reconnectTimer = setTimeout(() => {
      reconnectTimer = null
      connect()
    }, 3 * 1000)
  }
}

//连接成功
function onConnected(frame) {
  mqttConnected = true
  console.log('MQ连接成功')

  //重新连接后所有主题需要重新订阅
  for (let obj of topicList) {
    console.log('订阅topic: ' + obj.topic)
    obj.subscription = mqttClient.subscribe(obj.topic, obj.cb, obj.onSubscribeFailed)
  }
}

// 连接错误
function onFailed(frame) {
  mqttConnected = false
  console.log('MQ连接错误', frame)
  // 连接错误后重新连接
  if (reconnectTimer) {
    clearTimeout(reconnectTimer)
  }
  reconnectTimer = setTimeout(() => {
    reconnectTimer = null
    connect()
  }, 3 * 1000)
}

// 断开连接
function disconnect() {
  console.log('MQ断开连接')

  if (reconnectTimer) {
    clearTimeout(reconnectTimer)
    reconnectTimer = null
  }

  if (mqttClient) {
    mqttClient.disconnect(() => {
      console.log('MQ断开成功')
    })
  }
  mqttClient = null
  mqttConnected = false
  topicList.splice(0)
}

// 收到集团围栏变化MQ通知
function onMqTeamFenceUpdate(frame) {
  let msg = JSON.parse(frame.body)
  console.log('收到集团围栏变化MQ通知', msg)
  window.vue.$store.commit('refreshAllFences')
}

// 收到集团标注点变化MQ通知
function onMqTeamAnnotationUpdate(frame) {
  let msg = JSON.parse(frame.body)
  console.log('收到集团标注点变化MQ通知', msg)
  window.vue.$store.commit('refreshAllTaggings')
}

// 收到集团巡更点变化MQ通知
function onMqTeamBeaconUpdate(frame) {
  if(window.vue.$store.state.config.brand != 'liaoning'){
    return
  }
  let msg = JSON.parse(frame.body)
  console.log('收到集团巡更点变化MQ通知', msg)
  window.vue.$store.commit('refreshAllBeacons')
}

// 收到集团组织结构MQ通知
function onMqTeamOrgUpdate(frame) {
  let msg = JSON.parse(frame.body)
  console.log('收到集团组织结构MQ通知', msg)
  window.vue.$store.commit('refreshTeamUsers')
}

// 收到集团群组更新MQ通知
function onMqTeamGroupUpdate(frame) {
  let msg = JSON.parse(frame.body)
  console.log('收到集团群组更新MQ通知', msg)
  window.vue.$store.commit('refreshAllGroups')
}

// 收到集团成员更新MQ通知
function onMqTeamUserUpdate(frame) {
  let msg = JSON.parse(frame.body)
  console.log('收到集团成员更新MQ通知', msg)
  window.vue.$store.commit('refreshTeamUsers')
}

// 收到用户在线在组状态变化MQ通知
function onMqTeamUserStatusUpdate(frame) {
  let msg = JSON.parse(frame.body)
  console.log('收到用户在线在组状态变化MQ通知', msg)
  window.vue.$store.commit('updateUserStatus', msg)
  VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_USER_STATUS, msg)
}

// 收到视频推流通知、停止推流通知、事件通知、请假通知、会议通知MQ通知
function onMqUserNotify(frame) {
  let msg = JSON.parse(frame.body)
  if (msg.type == 'meeting_notify') {
    if(window.vue.$store.state.config.brand == 'yuntian'){
      return
    }
    // 收到会议邀请MQ通知
    console.log('收到会议邀请MQ通知', msg)
    // 更新会议列表
    VueEvent.eventBus.$emit(VueEvent.EVT_REFRESH_METTING_LIST)
    window.vue
      .$confirm(
        window.vue.$store.getters.getAllUserList[msg.meetingNotify.host].name + '邀请你加入视频会议，是否加入？',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'info',
        }
      )
      .then((res) => {
        if (!'mediaDevices' in navigator || !'getUserMedia' in navigator.mediaDevices ){
          this.$confirm('浏览器不支持音视频通话', {
            confirmButtonText: '确认',
            type: 'warning',
            showClose:false,
            showCancelButton:false
          })
          return
        }
        navigator.mediaDevices.getUserMedia({video: true, audio: true}).then(stream=>{
          stream.getTracks().forEach((track) => {
            track && track.stop()
          })
          if (window.vue.$store.state.videoState == 0) {
            // 没有进行其他视频操作
            VueEvent.eventBus.$emit(VueEvent.EVT_JOIN_METTING_VIDEO, msg.meetingNotify)
          } else if (window.vue.$store.state.videoState == 1) {
            // 正在观看终端视频推流
            window.vue
                .$confirm('当前正在观看终端视频推流，加入视频会议将退出观看终端视频推流，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_PUSH_VIDEO)
                  VueEvent.eventBus.$emit(VueEvent.EVT_JOIN_METTING_VIDEO, msg.meetingNotify)
                })
                .catch((err) => {})
          } else if (window.vue.$store.state.videoState == 2) {
            // 正在参加单人视频会商
            window.vue
                .$confirm('当前正在参加视频会商，加入视频会议将退出视频会商，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_SINGLE_PERSON_VIDEO)
                  VueEvent.eventBus.$emit(VueEvent.EVT_JOIN_METTING_VIDEO, msg.meetingNotify)
                })
                .catch((err) => {})
          } else if (window.vue.$store.state.videoState == 3) {
            // 正在参加多人视频会商
            window.vue
                .$confirm('当前正在参加视频会商，加入视频会议将退出视频会商，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_MULTI_PLAYER_VIDEO)
                  VueEvent.eventBus.$emit(VueEvent.EVT_JOIN_METTING_VIDEO, msg.meetingNotify)
                })
                .catch((err) => {})
          } else if (window.vue.$store.state.videoState == 4) {
            // 正在参加不是自己创建的会议
            window.vue
                .$confirm('当前正在参加视频会议，加入视频会议将退出当前视频会议，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_METTING_VIDEO)
                  VueEvent.eventBus.$emit(VueEvent.EVT_JOIN_METTING_VIDEO, msg.meetingNotify)
                })
                .catch((err) => {})
          } else if (window.vue.$store.state.videoState == 5) {
            // 正在参加自己创建的会议
            window.vue
                .$confirm('当前正在参加视频会议，加入视频会议将退出当前视频会议，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_MY_METTING_VIDEO)
                  VueEvent.eventBus.$emit(VueEvent.EVT_JOIN_METTING_VIDEO, msg.meetingNotify)
                })
                .catch((err) => {})
          } else if (window.vue.$store.state.videoState == 6) {
            // 正在观看用户视频上传回放
            window.vue
                .$confirm('当前正在观看用户视频上传回放，加入视频会议将退出观看用户视频上传回放，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_PUSH_VIDEO_PLAYBACK)
                  VueEvent.eventBus.$emit(VueEvent.EVT_JOIN_METTING_VIDEO, msg.meetingNotify)
                })
                .catch((err) => {})
          } else if (window.vue.$store.state.videoState == 7) {
            // 正在观看视频会议回放
            window.vue
                .$confirm('当前正在观看视频会议回放，加入视频会议将退出观看视频会议回放，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_METTING_VIDEO_PLAYBACK)
                  VueEvent.eventBus.$emit(VueEvent.EVT_JOIN_METTING_VIDEO, msg.meetingNotify)
                })
                .catch((err) => {})
          }
        }).catch((error) => {
          let message = error.message || error
          let response = {
            'permission denied': '浏览器禁止本页面使用摄像头或麦克风，请开启相关的权限',
            'requested device not found': '未检测到摄像头或麦克风',
            'could not start video source': '无法访问到摄像头或麦克风，请重新插拔后重试'
          }
          this.$confirm(response[ message.toLowerCase() ] || '未知错误', {
            confirmButtonText: '确认',
            type: 'warning',
            showClose:false,
            showCancelButton:false
          })
        })
      })
      .catch((err) => {})
  } else if (msg.type == 'meeting_kick') {
    if(window.vue.$store.state.config.brand == 'yuntian'){
      return
    }
    // 收到强制会议成员（本机）下线MQ通知
    console.log('收到强制会议成员下线MQ通知', msg)
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_METTING_KICK, msg.meetingKick)
    // 更新会议列表
    VueEvent.eventBus.$emit(VueEvent.EVT_REFRESH_METTING_LIST)
  } else if (msg.type == 'meeting_dismiss') {
    if(window.vue.$store.state.config.brand == 'yuntian'){
      return
    }
    // 收到会议结束MQ通知
    console.log('收到会议结束MQ通知', msg)
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_METTING_DISMISS, msg.meetingDismiss)
    // 更新会议列表
    VueEvent.eventBus.$emit(VueEvent.EVT_REFRESH_METTING_LIST)
  } else if (msg.type == 'video_notify') {
    if(window.vue.$store.state.config.brand == 'yuntian'){
      return
    }
    if (msg.action == 'push') {
      // 收到终端推流MQ通知
      console.log('收到终端推流MQ通知', msg)

      let time=new Date().getTime()/1000
      let noticeDispatch=true
      for(let i=0;i<sosUidList.length;i++){
        if(sosUidList[i].uid==msg.data.uid){
          if(time-sosUidList[i].time<=3){
            noticeDispatch=false
          }
          break
        }
      }

      if (
        window.vue.$store.getters.getAllUserList[msg.data.uid] &&
        window.vue.$store.getters.getAllUserList[msg.data.uid].inChargeOf
      ) {
        window.vue.$store.commit('updateUserRealVideo', msg.data)
        if(!noticeDispatch){
          return
        }
        window.vue
          .$confirm(window.vue.$store.getters.getAllUserList[msg.data.uid].name + '正在上传视频，是否观看？', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'info',
          })
          .then((res) => {
            if (window.vue.$store.state.videoState == 0) {
              // 没有进行其他视频操作
              VueEvent.eventBus.$emit(VueEvent.EVT_PUSH_VIDEO, window.vue.$store.getters.getAllUserList[msg.data.uid])
            } else if (window.vue.$store.state.videoState == 1) {
              // 正在观看终端视频推流
              VueEvent.eventBus.$emit(VueEvent.EVT_PUSH_VIDEO, window.vue.$store.getters.getAllUserList[msg.data.uid])
            } else if (window.vue.$store.state.videoState == 2) {
              // 正在参加单人视频会商
              window.vue
                .$confirm('当前正在参加视频会商，观看终端视频推流将退出视频会商，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_SINGLE_PERSON_VIDEO)
                  VueEvent.eventBus.$emit(
                    VueEvent.EVT_PUSH_VIDEO,
                    window.vue.$store.getters.getAllUserList[msg.data.uid]
                  )
                })
                .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 3) {
              // 正在参加多人视频会商
              window.vue
                .$confirm('当前正在参加视频会商，观看终端视频推流将退出视频会商，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_MULTI_PLAYER_VIDEO)
                  VueEvent.eventBus.$emit(
                    VueEvent.EVT_PUSH_VIDEO,
                    window.vue.$store.getters.getAllUserList[msg.data.uid]
                  )
                })
                .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 4) {
              // 正在参加不是自己创建的会议
              window.vue
                .$confirm('当前正在参加视频会议，观看终端视频推流将退出视频会议，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_METTING_VIDEO)
                  VueEvent.eventBus.$emit(
                    VueEvent.EVT_PUSH_VIDEO,
                    window.vue.$store.getters.getAllUserList[msg.data.uid]
                  )
                })
                .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 5) {
              // 正在参加自己创建的会议
              window.vue
                .$confirm('当前正在参加视频会议，观看终端视频推流将退出视频会议，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_MY_METTING_VIDEO)
                  VueEvent.eventBus.$emit(
                    VueEvent.EVT_PUSH_VIDEO,
                    window.vue.$store.getters.getAllUserList[msg.data.uid]
                  )
                })
                .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 6) {
              // 正在观看用户视频上传回放
              window.vue
                .$confirm('当前正在观看用户视频上传回放，观看终端视频推流将退出观看用户视频上传回放，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_PUSH_VIDEO_PLAYBACK)
                  VueEvent.eventBus.$emit(
                    VueEvent.EVT_PUSH_VIDEO,
                    window.vue.$store.getters.getAllUserList[msg.data.uid]
                  )
                })
                .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 7) {
              // 正在观看视频会议回放
              window.vue
                .$confirm('当前正在观看视频会议回放，观看终端视频推流将退出观看视频会议回放，是否继续？', {
                  confirmButtonText: '确认',
                  cancelButtonText: '取消',
                  type: 'warning',
                })
                .then((res) => {
                  VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_METTING_VIDEO_PLAYBACK)
                  VueEvent.eventBus.$emit(
                    VueEvent.EVT_PUSH_VIDEO,
                    window.vue.$store.getters.getAllUserList[msg.data.uid]
                  )
                })
                .catch((err) => {})
            }
          })
          .catch((err) => {})
      }
    } else if (msg.action == 'unpublish') {
      // 收到终端停止推流MQ通知
      console.log('收到终端停止推流MQ通知', msg)
      if (
        window.vue.$store.getters.getAllUserList[msg.data.userId] &&
        window.vue.$store.getters.getAllUserList[msg.data.userId].inChargeOf
      ) {
        let obj = { uid: msg.data.userId, puller: null, realUrl: null }
        window.vue.$store.commit('updateUserRealVideo', obj)
        VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_PUSH_UNPUBLISH, msg.data)
      }
    } else if (msg.action == 'video_call_invitation') {
      // 收到终端发起视频会商MQ通知
      console.log('收到终端发起视频会商MQ通知', msg)
      window.vue
        .$confirm(
          window.vue.$store.getters.getAllUserList[msg.videoInfo.userId].name + '邀请你加入视频会商，是否加入？',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'info',
          }
        )
        .then((res) => {
          if (!'mediaDevices' in navigator || !'getUserMedia' in navigator.mediaDevices ){
            this.$confirm('浏览器不支持音视频通话', {
              confirmButtonText: '确认',
              type: 'warning',
              showClose:false,
              showCancelButton:false
            })
            return
          }
          navigator.mediaDevices.getUserMedia({video: true, audio: true}).then(stream=>{
            stream.getTracks().forEach((track) => {
              track && track.stop()
            })
            if (window.vue.$store.state.videoState == 0) {
              // 没有进行其他视频操作
              if (msg.videoInfo.type == 2) {
                // 单人视频会商
                VueEvent.eventBus.$emit(VueEvent.EVT_SINGLE_PERSON_VIDEO, {res:msg.videoInfo,captureVideo:true})
              } else if (msg.videoInfo.type == 3||msg.videoInfo.type == 5) {
                // msg.videoInfo.type == 3多人视频会商
                // msg.videoInfo.type == 5多人语音会议（无视频功能）
                VueEvent.eventBus.$emit(VueEvent.EVT_MULTI_PLAYER_VIDEO, {res:msg.videoInfo,captureVideo:msg.videoInfo.type == 3?true:false})
              }
            } else if (window.vue.$store.state.videoState == 1) {
              // 正在观看终端视频推流
              window.vue
                  .$confirm('当前正在观看终端视频推流，加入视频会商将退出观看终端视频推流，是否继续？', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                  })
                  .then((res) => {
                    VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_PUSH_VIDEO)
                    if (msg.videoInfo.type == 2) {
                      // 单人视频会商
                      VueEvent.eventBus.$emit(VueEvent.EVT_SINGLE_PERSON_VIDEO, {res:msg.videoInfo,captureVideo:true})
                    } else if (msg.videoInfo.type == 3||msg.videoInfo.type == 5) {
                      // msg.videoInfo.type == 3多人视频会商
                // msg.videoInfo.type == 5多人语音会议（无视频功能）
                      VueEvent.eventBus.$emit(VueEvent.EVT_MULTI_PLAYER_VIDEO, {res:msg.videoInfo,captureVideo:msg.videoInfo.type == 3?true:false})
                    }
                  })
                  .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 2) {
              // 正在参加单人视频会商
              window.vue
                  .$confirm('当前正在参加视频会商，加入视频会商将退出当前视频会商，是否继续？', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                  })
                  .then((res) => {
                    VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_SINGLE_PERSON_VIDEO)
                    if (msg.videoInfo.type == 2) {
                      // 单人视频会商
                      VueEvent.eventBus.$emit(VueEvent.EVT_SINGLE_PERSON_VIDEO, {res:msg.videoInfo,captureVideo:true})
                    } else if (msg.videoInfo.type == 3||msg.videoInfo.type == 5) {
                      // msg.videoInfo.type == 3多人视频会商
                // msg.videoInfo.type == 5多人语音会议（无视频功能）
                      VueEvent.eventBus.$emit(VueEvent.EVT_MULTI_PLAYER_VIDEO, {res:msg.videoInfo,captureVideo:msg.videoInfo.type == 3?true:false})
                    }
                  })
                  .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 3) {
              // 正在参加多人视频会商
              window.vue
                  .$confirm('当前正在参加视频会商，加入视频会商将退出当前视频会商，是否继续？', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                  })
                  .then((res) => {
                    VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_MULTI_PLAYER_VIDEO)
                    if (msg.videoInfo.type == 2) {
                      // 单人视频会商
                      VueEvent.eventBus.$emit(VueEvent.EVT_SINGLE_PERSON_VIDEO, {res:msg.videoInfo,captureVideo:true})
                    } else if (msg.videoInfo.type == 3||msg.videoInfo.type == 5) {
                      // msg.videoInfo.type == 3多人视频会商
                // msg.videoInfo.type == 5多人语音会议（无视频功能）
                      VueEvent.eventBus.$emit(VueEvent.EVT_MULTI_PLAYER_VIDEO, {res:msg.videoInfo,captureVideo:msg.videoInfo.type == 3?true:false})
                    }
                  })
                  .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 4) {
              // 正在参加不是自己创建的会议
              window.vue
                  .$confirm('当前正在参加视频会议，加入视频会商将退出视频会议，是否继续？', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                  })
                  .then((res) => {
                    VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_METTING_VIDEO)
                    if (msg.videoInfo.type == 2) {
                      // 单人视频会商
                      VueEvent.eventBus.$emit(VueEvent.EVT_SINGLE_PERSON_VIDEO, {res:msg.videoInfo,captureVideo:true})
                    } else if (msg.videoInfo.type == 3||msg.videoInfo.type == 5) {
                      // msg.videoInfo.type == 3多人视频会商
                // msg.videoInfo.type == 5多人语音会议（无视频功能）
                      VueEvent.eventBus.$emit(VueEvent.EVT_MULTI_PLAYER_VIDEO, {res:msg.videoInfo,captureVideo:msg.videoInfo.type == 3?true:false})
                    }
                  })
                  .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 5) {
              // 正在参加自己创建的会议
              window.vue
                  .$confirm('当前正在参加视频会议，加入视频会商将退出视频会议，是否继续？', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                  })
                  .then((res) => {
                    VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_MY_METTING_VIDEO)
                    if (msg.videoInfo.type == 2) {
                      // 单人视频会商
                      VueEvent.eventBus.$emit(VueEvent.EVT_SINGLE_PERSON_VIDEO, {res:msg.videoInfo,captureVideo:true})
                    } else if (msg.videoInfo.type == 3||msg.videoInfo.type == 5) {
                      // msg.videoInfo.type == 3多人视频会商
                // msg.videoInfo.type == 5多人语音会议（无视频功能）
                      VueEvent.eventBus.$emit(VueEvent.EVT_MULTI_PLAYER_VIDEO, {res:msg.videoInfo,captureVideo:msg.videoInfo.type == 3?true:false})
                    }
                  })
                  .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 6) {
              // 正在观看用户视频上传回放
              window.vue
                  .$confirm('当前正在观看用户视频上传回放，加入视频会商将退出观看用户视频上传回放，是否继续？', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                  })
                  .then((res) => {
                    VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_PUSH_VIDEO_PLAYBACK)
                    if (msg.videoInfo.type == 2) {
                      // 单人视频会商
                      VueEvent.eventBus.$emit(VueEvent.EVT_SINGLE_PERSON_VIDEO, {res:msg.videoInfo,captureVideo:true})
                    } else if (msg.videoInfo.type == 3||msg.videoInfo.type == 5) {
                      // msg.videoInfo.type == 3多人视频会商
                // msg.videoInfo.type == 5多人语音会议（无视频功能）
                      VueEvent.eventBus.$emit(VueEvent.EVT_MULTI_PLAYER_VIDEO, {res:msg.videoInfo,captureVideo:msg.videoInfo.type == 3?true:false})
                    }
                  })
                  .catch((err) => {})
            } else if (window.vue.$store.state.videoState == 7) {
              // 正在观看视频会议回放
              window.vue
                  .$confirm('当前正在观看视频会议回放，加入视频会商将退出观看视频会议回放，是否继续？', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                  })
                  .then((res) => {
                    VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_METTING_VIDEO_PLAYBACK)
                    if (msg.videoInfo.type == 2) {
                      // 单人视频会商
                      VueEvent.eventBus.$emit(VueEvent.EVT_SINGLE_PERSON_VIDEO, {res:msg.videoInfo,captureVideo:true})
                    } else if (msg.videoInfo.type == 3||msg.videoInfo.type == 5) {
                      // msg.videoInfo.type == 3多人视频会商
                // msg.videoInfo.type == 5多人语音会议（无视频功能）
                      VueEvent.eventBus.$emit(VueEvent.EVT_MULTI_PLAYER_VIDEO, {res:msg.videoInfo,captureVideo:msg.videoInfo.type == 3?true:false})
                    }
                  })
                  .catch((err) => {})
            }
          }).catch((error) => {
            let message = error.message || error
            let response = {
              'permission denied': '浏览器禁止本页面使用摄像头或麦克风，请开启相关的权限',
              'requested device not found': '未检测到摄像头或麦克风',
              'could not start video source': '无法访问到摄像头或麦克风，请重新插拔后重试'
            }
            this.$confirm(response[ message.toLowerCase() ] || '未知错误', {
              confirmButtonText: '确认',
              type: 'warning',
              showClose:false,
              showCancelButton:false
            })
          })
        })
        .catch((err) => {
          let callId=msg.videoInfo.id
          window.vue.$api.exitVideoCall({callId:callId}).then(rsp=>{
            this.$emit('closed')
          })
        })
    } else if (msg.action == 'reject') {
      // 收到终端拒绝拉流、视频会商、视频会议MQ通知
      console.log('收到终端拒绝拉流、视频会商、视频会议MQ通知', msg)
      if (!msg.data.room) {
        // 拒绝视频会议
        window.vue.$message.warning(window.vue.$store.getters.getAllUserList[msg.data.userId].name + '拒绝加入视频会议')
      } else if (msg.data.room.indexOf(msg.data.userId + '_') > -1) {
        // 拒绝拉流
        window.vue.$message.warning(window.vue.$store.getters.getAllUserList[msg.data.userId].name + '拒绝拉流')
      } else if (msg.data.room.indexOf('room_') > -1) {
        // 拒绝视频会商
        window.vue.$message.warning(window.vue.$store.getters.getAllUserList[msg.data.userId].name + '拒绝加入视频会商')
        VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_CONSULTATION_REJECT, msg.data)
      }
    }
  } else if (msg.type == 'notification') {
    // 收到消息助手MQ通知
    console.log('收到消息助手MQ通知', msg)
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_MESSAGE_UPDATE, msg.data)
    window.vue.$store.commit('updateMessageTips', true)
  } else if (msg.type == 'event_update') {
    if(window.vue.$store.state.config.brand != 'liaoning'){
      return
    }
    // 收到事件MQ通知
    console.log('收到事件MQ通知', msg)
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_EVENT_UPDATE, msg)
  } else if (msg.type == 'task_update') {
    if(window.vue.$store.state.config.brand == 'yuntian'){
      return
    }
    // 收到任务MQ通知
    console.log('收到任务MQ通知', msg)
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_TASK_UPDATE, msg)
  } else if (msg.type == 'absence_request') {
    if(window.vue.$store.state.config.brand != 'liaoning'){
      return
    }
    // 收到请假MQ通知
    console.log('收到请假MQ通知', msg)
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_LEAVE_UPDATE, msg)
  } else if (msg.type == 'media_notify') {
    if(window.vue.$store.state.config.brand == 'yuntian'){
      return
    }
    // 收到媒体记录MQ通知
    console.log('收到媒体记录MQ通知', msg)
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_MEDIA_RECORD_UPDATE, msg)
  } else if (msg.type == 'video_dismiss') {
    if(window.vue.$store.state.config.brand == 'yuntian'){
      return
    }
    // 收到视频通话（会商）结束MQ通知
    console.log('收到视频通话（会商）结束MQ通知', msg)
    if(msg.action=='video_call_dismiss'){
      VueEvent.eventBus.$emit(VueEvent.EVT_CLOSE_VIDEO_CONFERENCING, msg)
    }
  }
}

// 收到任务、事件附件上传MQ通知
function onMqEnclosure(frame) {
  if(window.vue.$store.state.config.brand == 'yuntian'){
    return
  }
  let msg = JSON.parse(frame.body)
  console.log('收到任务、事件附件上传MQ通知', msg)
  if (msg.action == 'task') {
    // 收到任务附件上传MQ通知
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_TASK_ENCLOSURE, msg)
    // 收到附件上传后 更新媒体记录列表
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_MEDIA_RECORD_UPDATE, msg)
  } else if (msg.action == 'event') {
    // 收到事件附件上传MQ通知
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_EVENT_ENCLOSURE, msg)
    // 收到附件上传后 更新媒体记录列表
    VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_MEDIA_RECORD_UPDATE, msg)
  }
}

// 收到SOS报警MQ通知
function onMqTeamSos(frame){
  let msg = JSON.parse(frame.body)
  console.log('收到SOS报警MQ通知：', msg)
  if(!window.vue.$store.state.allUserList[msg.userId]){
    return
  }
  if (msg.action == 0) {
    // SOS报警
    VueEvent.eventBus.$emit(VueEvent.EVT_SOS_WARNING_SHOW, msg)

    if(sosUidList.length==0){
      sosUidList.push({uid:msg.userId,time:msg.time})
    }else{
      let pushUid=true
      for(let i=0;i<sosUidList.length;i++){
        if(sosUidList[i].uid==msg.userId){
          sosUidList[i].time=msg.time
          pushUid=false
          break
        }
      }
      if(pushUid){
        sosUidList.push({uid:msg.userId,time:msg.time})
      }
    }
  }
}

// 收到卫星电话开关机MQ通知
function onMqPowerStatus(frame){
  let msg = JSON.parse(frame.body)
  console.log('收到卫星电话开关机MQ通知：', msg)
  if(!window.vue.$store.state.allUserList[msg.uid]){
    return
  }
  window.vue.$store.commit('updatePowerStatus', msg)
  VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_POWER_STATUS, msg)
}

/**
 *  订阅群组录音MQ
 * */
function subscribePocRecord(groupid) {
  let topic = '/topic/voice_record_group_' + groupid
  subscribeTopic(topic, onMqVoiceRecord)
}

/**
 *  取消群组录音MQ
 * */
function unsubscribePocRecord(groupid) {
  let topic = '/topic/voice_record_group_' + groupid
  unsubscribeTopic(topic)
}

// 收到群组录音记录MQ通知
function onMqVoiceRecord(frame) {
  let msg = JSON.parse(frame.body)
  console.log('收到群组录音记录MQ通知', msg)
  VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_GROUP_VOICE_UPDATE, msg)
}

/**
 *  订阅群组消息MQ
 * */
function subscribeGroupMessage(groupid) {
  let topic = '/topic/group_notify_' + groupid
  subscribeTopic(topic, onMqGroupMsg)
}

/**
 *  取消订阅群组消息MQ
 * */
function unsubscribeGroupMessage(groupid) {
  let topic = '/topic/group_notify_' + groupid
  unsubscribeTopic(topic)
}

// 收到群组消息MQ通知
function onMqGroupMsg(frame) {
  let msg = JSON.parse(frame.body)
  console.log('收到群组消息MQ通知', msg)
  VueEvent.eventBus.$emit(VueEvent.EVT_MQTT_GROUP_MESSAGE_UPDATE, msg)
}

/**
 *  订阅会议MQ
 * */
function subscribeMeeting(meetingId) {
  let topic = '/topic/meeting_notify_' + meetingId
  subscribeTopic(topic, onMqMeeting)
}

/**
 *  取消订阅会议MQ
 * */
function unsubscribeMeeting(meetingId) {
  let topic = '/topic/meeting_notify_' + meetingId
  unsubscribeTopic(topic)
}

// 收到会议信息MQ通知
function onMqMeeting(frame) {
  if(window.vue.$store.state.config.brand == 'yuntian'){
    return
  }
  let msg = JSON.parse(frame.body)
  console.log('收到会议信息MQ通知：', msg)
  if (msg.type == 'meeting_notify' && msg.action == 'set_speaker') {
    // 会议设置主讲人
    VueEvent.eventBus.$emit(VueEvent.EVT_CLEAR_ONE_TRACE_USER, msg.meetingNotify)
  }
}

// 订阅定位信息MQ
function reSubscribeUserLocation() {
  let orgs = window.vue.$store.state.subOrgs
  let exists = [] // 对应orgs每个下标，记录是否已经订阅

  let prefixLen = '/topic/user_location_update_'.length
  let myTeamId = window.vue.$store.getters.getMyTeamId

  let i
  for (i = 0; i < topicList.length; i++) {
    let topicName = topicList[i].topic
    if (topicName.indexOf('/topic/user_location_update_') === 0) {
      let subStr = topicName.slice(prefixLen)
      let arr = subStr.split('_')
      if (arr.length !== 2) {
        console.log('bad topic ' + topicName)
        unsubscribeTopic(topicName)
        i--
      } else {
        let teamId = Number(arr[0])

        if (myTeamId!=1&&teamId !== myTeamId) {
          console.log('bad topic, teamId mismatch ' + topicName)
          unsubscribeTopic(topicName)
          i--
        } else {
          let orgId = Number(arr[1])
          let found = false
          for (let j in orgs) {
            if (orgs[j] === orgId) {
              found = true
              exists[j] = true
              break
            }
          }
          if (found) {
            // 保留这个订阅
            // do nothing
          } else {
            unsubscribeTopic(topicName)
            i--
          }
        }
      }
    }
  }

  // 将新增的org订阅上
  for (i = 0; i < orgs.length; i++) {
    if (!exists[i]) {
      subscribeTopic('/topic/user_location_update_' + myTeamId + '_' + orgs[i], onMqUserLocationUpdate)
    }
  }
}

// 收到定位信息MQ通知
function onMqUserLocationUpdate(frame) {
  let msg = JSON.parse(frame.body)
  // 定位信息通知
  console.log('收到定位信息MQ通知：', msg)
  window.vue.$store.commit('updateUserLocation', msg)
}

export default {
  initMqtt,
  disconnect,
  subscribePocRecord,
  unsubscribePocRecord,
  subscribeGroupMessage,
  unsubscribeGroupMessage,
  subscribeMeeting,
  unsubscribeMeeting,
  reSubscribeUserLocation,
}
