const KeyUserName = 'LoginName'
const KeyPassword = 'LoginPwd'
const KeySessionId = 'LoginSessionID'
const KeyManagerId = 'LoginManagerID'
const KeyTeamId = 'LoginTeamID'
const KeyTeamName = 'LoginTeamName'
const KeyUserId = 'LoginUserID'
const KeyJavaToken = 'token'
const KeyLogoUrl = 'logoUrl'
const keyLogoText = 'stationLogoTxt'
const KeyNumber = 'KeyNumber'

function checkCookie() {
  var token = getCookie(KeyJavaToken)
  if (!token) {
    console.log('checkCookie failed, no token')
    return false
  }
  let teamId = Number(getCookie(KeyTeamId))
  if (!teamId || teamId === 0) {
    console.log('checkCookie failed, no teamId')
    return false
  }
  let teamName = getCookie(KeyTeamName)
  if (!teamName) {
    console.log('checkCookie failed, no teamName')
    return false
  }

  let uid = getCookie(KeyUserId)
  if (!uid || uid === 0) {
    console.log('checkCookie failed, no userId')
    return false
  }

  window.vue.$store.commit('setToken', token)
  window.vue.$store.commit('setMyTeamId', teamId)
  window.vue.$store.commit('setMyTeamName', teamName)
  window.vue.$store.commit('setMyUserId', uid)
  window.vue.$store.commit('setTokenInvalid', false)

  return true
}

function getCookie(cname) {
  if (window.vue.$store.getters.getConfig.pack_type == 'web') {
    var herf = window.location.hostname.split('.')[0]
    var name = herf + cname + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim()
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length)
    }
    return ''
  } else {
    return localStorage.getItem(cname)
  }
}

//变量名 值 标记
function setCookie(cname, cvalue, exdays) {
  if (window.vue.$store.getters.getConfig.pack_type != 'web') {
    localStorage.setItem(cname, cvalue)
  } else {
    var herf = window.location.hostname.split('.')[0]
    if (Math.floor(exdays) <= 0) {
      document.cookie = herf + cname + '=' + cvalue + '; '
    } else {
      var d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      var expires = 'expires=' + d.toGMTString()
      document.cookie = herf + cname + '=' + cvalue + '; ' + expires
    }
  }
}

//删除cookie
function clearCookies() {
  setCookie(KeyNumber, '', 0)
  setCookie(KeyUserName, '', 0)
  setCookie(KeyPassword, '', 0)
  setCookie(KeySessionId, '', 0)
  setCookie(KeyManagerId, '', 0)
  setCookie(KeyTeamId, '', 0)
  window.vue.$store.commit('setMyTeamId', 0)
  setCookie(KeyTeamName, '', 0)
  window.vue.$store.commit('setMyTeamName', '')
  setCookie(KeyUserId, '', 0)
  window.vue.$store.commit('setMyUserId', 0)
  setCookie(KeyJavaToken, '', 0)
  setCookie(KeyLogoUrl, '', 0)
  setCookie(keyLogoText, '', 0)
}

export default {
  setCookie,
  getCookie,
  checkCookie,
  clearCookies,
  KeyUserName,
  KeyPassword,
  KeySessionId,
  KeyManagerId,
  KeyTeamId,
  KeyTeamName,
  KeyUserId,
  KeyJavaToken,
  KeyLogoUrl,
  keyLogoText,
  KeyNumber,
}
