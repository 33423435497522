//values: [0, 1],
// 考勤打卡的类型枚举
export const ENUM_ATTENDANCE_TYPE = {
  ENUM_ATTENDANCE_TYPE_ON_WORK: {
    desc: '到岗',
    value: 0,
  },
  ENUM_ATTENDANCE_TYPE_OFF_WORK: {
    desc: '离岗',
    value: 1,
  },
}
