import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/light_index.css' //默认的element-ui主题样式
import '../public/customStyle/theme/deep_index.css' //自定义的element-ui主题样式
import * as api from './api/api.js'
import axios from 'axios'
import './js/common/common.js' //引入所用的常量

// hls(m3u8格式)视频播放
require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
import hls from 'videojs-contrib-hls';
import VideoPlayer from 'vue-video-player';

Vue.use(hls);
Vue.use(VideoPlayer);

Vue.use(ElementUI)
Vue.prototype.$api = api
Vue.prototype.$axios = axios
Vue.config.productionTip = true

window.vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
