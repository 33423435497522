// 引入axios进行请求
import axios from 'axios'
import Cookies from '../js/cookies'

// 请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送之前要做的处理
    config.timeout = 60 * 1000 //超时时间
    config.baseURL = window.vue.$store.getters.getBaseUrl //vuex获取请求地址
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: window.vue.$store.getters.getToken, //vuex获取token
    }
    return config
  },
  function (error) {
    // 处理请求错误
    return Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 任何在2xx范围内的状态代码都会触发此功能
    // 处理响应数据
    return response
  },
  function (error) {
    // 任何不在2xx范围内的状态代码都会触发此功能
    // 处理响应错误
    return error.response
  }
)

// 处理响应
const handleResponse = (result) => {
  return Promise.resolve(result)
}

export default {
  get(url, params = {}, onErr) {
    let queryString = []
    for (let key in params) {
      queryString.push(`${key}=${params[key]}`)
    }
    if (queryString.length > 0) {
      queryString = queryString.join('&')
      url += `?${queryString}`
    }
    return axios
      .get(url)
      .then((res) => {
        let status = res.status
        let data = res.data
        // 状态码等于200
        if (status === 200) {
          // 状态码等于200时，再次判断接口的返回信息，如果错误提示错误信息，如果正确则返回接口的返回信息
          // 这里需要根据后端接口进行匹配，每个项目都不一样，需自行修改
          if (data.code == 2000) {
            return data
          } else if (data.code == 4013) {
            // 登录已过期，
            if (!window.vue.$store.state.isTokenInvalid) {
              window.vue.$store.commit('setTokenInvalid', true)
              window.vue.$message.error('登录身份已过期，请重新登录')
              Cookies.clearCookies()
              window.vue.$router.push({ path: './login' })
            }
            throw new Error(data.msg)
          } else {
            // 其它错误
            if (onErr) {
              onErr()
            } else {
              window.vue.$message.error(data.msg)
            }
            throw new Error(data.msg)
          }
        } else {
          // 状态码不等于200时
          window.vue.$message.error(data.msg)
          throw new Error(data.msg)
        }
      })
      .then(handleResponse)
      .catch((error) => {
        return Promise.reject(error)
      })
  },

  post(url, params = {}, onErr) {
    return axios
      .post(url, params)
      .then((res) => {
        let status = res.status
        let data = res.data
        // 状态码等于200
        if (status === 200) {
          // 状态码等于200时，再次判断接口的返回信息，如果错误提示错误信息，如果正确则返回接口的返回信息
          // 这里需要根据后端接口进行匹配，每个项目都不一样，需自行修改
          if (data.code == 2000) {
            return data
          } else if (data.code == 4013) {
            // 登录已过期，
            if (!window.vue.$store.state.isTokenInvalid) {
              window.vue.$store.commit('setTokenInvalid', true)
              window.vue.$message.error('登录身份已过期，请重新登录')
              Cookies.clearCookies()
              window.vue.$router.push({ path: './login' })
            }
            throw new Error(data.msg)
          } else {
            // 其它错误
            if (onErr) {
              onErr()
            } else {
              window.vue.$message.error(data.msg)
            }
            throw new Error(data.msg)
          }
        } else {
          // 状态码不等于200时
          window.vue.$message.error(data.msg)
          throw new Error(data.msg)
        }
      })
      .then(handleResponse)
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  put(url, params = {}, onErr) {
    return axios
      .put(url, params)
      .then((res) => {
        let status = res.status
        let data = res.data
        // 状态码等于200
        if (status === 200) {
          // 状态码等于200时，再次判断接口的返回信息，如果错误提示错误信息，如果正确则返回接口的返回信息
          // 这里需要根据后端接口进行匹配，每个项目都不一样，需自行修改
          if (data.code == 2000) {
            return data
          } else if (data.code == 4013) {
            // 登录已过期，
            if (!window.vue.$store.state.isTokenInvalid) {
              window.vue.$store.commit('setTokenInvalid', true)
              window.vue.$message.error('登录身份已过期，请重新登录')
              Cookies.clearCookies()
              window.vue.$router.push({ path: './login' })
            }
            throw new Error(data.msg)
          } else {
            // 其它错误
            if (onErr) {
              onErr()
            } else {
              window.vue.$message.error(data.msg)
            }
            throw new Error(data.msg)
          }
        } else {
          // 状态码不等于200时
          window.vue.$message.error(data.msg)
          throw new Error(data.msg)
        }
      })
      .then(handleResponse)
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  delete(url, params = {}, onErr) {
    return axios
      .delete(url, params)
      .then((res) => {
        let status = res.status
        let data = res.data
        // 状态码等于200
        if (status === 200) {
          // 状态码等于200时，再次判断接口的返回信息，如果错误提示错误信息，如果正确则返回接口的返回信息
          // 这里需要根据后端接口进行匹配，每个项目都不一样，需自行修改
          if (data.code == 2000) {
            return data
          } else if (data.code == 4013) {
            // 登录已过期，
            if (!window.vue.$store.state.isTokenInvalid) {
              window.vue.$store.commit('setTokenInvalid', true)
              window.vue.$message.error('登录身份已过期，请重新登录')
              Cookies.clearCookies()
              window.vue.$router.push({ path: './login' })
            }
            throw new Error(data.msg)
          } else {
            // 其它错误
            if (onErr) {
              onErr()
            } else {
              window.vue.$message.error(data.msg)
            }
            throw new Error(data.msg)
          }
        } else {
          // 状态码不等于200时
          window.vue.$message.error(data.msg)
          throw new Error(data.msg)
        }
      })
      .then(handleResponse)
      .catch((error) => {
        return Promise.reject(error)
      })
  },
}
