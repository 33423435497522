import Vue from 'vue'

import HOMEPAGE_CONST from './constant/homePage.js'

import Enum from './enum/enum.js'
import EnumModule from './enum/enumModule.js'

Vue.prototype.$HOMEPAGE_CONST = HOMEPAGE_CONST // 挂在原型上，方便使用

Vue.use(Enum, EnumModule)
