import Cookies from './cookies'
import VueEvent from './common/VueEvent'

var IntercomInfo = {
  groupName: '', //群组名称
  groupId: '', //群组Id
  state: '', //对讲状态
  discoursePower: '', //话权
  img: '', //图片
} //要传递给对讲弹窗的信息
var Intercom_plugin = null //null：对讲插件未打开时打开对讲插件 1：对讲插件已打开
var global_SerialNum = 0
//{WebSocket} 语音websocket 实例
var global_talkback_server_socket
var global_websocket_already_login = false //websocket 是否已经登录成功
var TALK_SERVER_RECONNECT_TIMER //掉线后自动重新登录的定时器
var calling_group_id //当前呼叫的群组ID
var my_calling_group_id //调度台主动发起呼叫的群组ID
var global_talkback_talk_state = false //是否正在对讲；true:鼠标左键或者空格按下；false:松开
var groupid_to_listen = 0 //添加监听的群组id
var groupid_to_unlisten = 0 //取消监听的群组id
var GLOBAL_C_GROUPCHAT_GID
var monitorCallback = null //添加或取消监听的回调
var listenningGroups = [] //已监听的群组
// 语音服务器websocket连接
function initVoiceWebsocket() {
  if ('WebSocket' in window) {
    if (!global_talkback_server_socket) {
      global_talkback_server_socket = new WebSocket('ws://localhost:1338/poc?ver=asdf')
    }
    global_talkback_server_socket.onopen = function () {
      console.log('语音websocket 连接成功')
      window.vue.$store.commit('updateShowTips', true)
      window.vue.$store.commit('updateLoginStates', '对讲账号正在登录')
      reloginTalkServer()
    }
    global_talkback_server_socket.onmessage = function (evt) {
      let obj = JSON.parse(evt.data)
      console.log('收到语音服务器信息：', obj)
      let tokenState = ''

      switch (obj.Code) {
        case 40000:
          //身份认证回应
          if (obj.Result == 2200) {
            //认证成功
            global_websocket_already_login = true
            window.vue.$store.commit('updateLoginStates', '对讲账号登录成功')
            window.vue.$store.commit('updateShowTips', false)
          } else if (obj.Result == 2228) {
            // 正在登陆
            window.vue.$message.warning(obj.Message)
          } else if (obj.Result == 2299) {
            cope2209DropLine()
          } else {
            window.vue.$message.warning(obj.Message)
          }
          break
        case 40002:
          //设置终端pc参数
          if (obj.Result == 2200) {
            //设置成功
            window.vue.$message.success('设置成功')
          } else if (obj.Result == 2299) {
            cope2209DropLine()
          } else {
            window.vue.$message.error('设置失败(IP地址或端口号设置错误)')
          }
          break

        case 40003:
          //获取pc终端参数
          if (obj.Result == 2299) {
            cope2209DropLine()
          }
          break
        case 40004:
          //添加监听
          if (obj.Result == 2200) {
            if (groupid_to_listen > 0) {
              window.vue.$message.success('添加监听成功')
              listenningGroups.push(groupid_to_listen)
              groupid_to_listen = 0
              if (monitorCallback) {
                monitorCallback()
              }
              monitorCallback = null
            }
          } else {
            window.vue.$message.warning(obj.Message)
            monitorCallback = null
          }
          break

        case 40005:
          //取消监听
          if (obj.Result == 2200) {
            window.vue.$message.success('取消监听成功')
            let isindex = -1
            listenningGroups.forEach((item, index) => {
              if (item == groupid_to_unlisten) {
                isindex = index
              }
            })
            listenningGroups.splice(isindex, 1)
            groupid_to_unlisten = 0
            if (monitorCallback) {
              monitorCallback()
            }
            monitorCallback = null
          } else {
            window.vue.$message.warning(obj.Message)
            monitorCallback = null
          }
          break
        case 40006:
          //发起预定义组呼
          if (obj.Result == 2200) {
            if (obj.Gname != null) {
              IntercomInfo = {
                groupName: obj.Gname,
                groupId: calling_group_id,
                state: '空闲',
                discoursePower: '按下鼠标左键或空格键申请话权',
                img: require('../assets/mainFrame/talk_bt_Talking.png'),
              }
              VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
            } else {
              // 组呼
              IntercomInfo = {
                groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
                groupId: calling_group_id,
                state: '空闲',
                discoursePower: '按下鼠标左键或空格键申请话权',
                img: require('../assets/mainFrame/talk_bt_Talking.png'),
              }
              VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
            }
            window.vue.$store.commit('updateRecentGroupList', calling_group_id)
          } else if (obj.Result == 2208) {
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_CLOSE)
            window.vue.$message.warning('用户已离线')
          } else if (obj.Result == 2209) {
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_CLOSE)
            window.vue.$message.warning('组不存在')
          } else if (obj.Result == 2210) {
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_CLOSE)
            window.vue.$message.warning('当前用户不属于该群组')
          } else if (obj.Result == 2299) {
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_CLOSE)
            cope2209DropLine()
          } else {
            window.vue.$message.warning(obj.Message)
          }
          break
        case 40007:
          //发起临时组呼
          if (obj.Result == 2200) {
            IntercomInfo = {
              groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
              groupId: calling_group_id,
              state: '空闲',
              discoursePower: '按下鼠标左键或空格键申请话权',
              img: require('../assets/mainFrame/talk_bt_Talking.png'),
            }
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
          } else if (obj.Result == 2213) {
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_CLOSE)
            window.vue.$message.warning('创建临时组失败')
          } else if (obj.Result == 2299) {
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_CLOSE)
            cope2209DropLine()
          } else {
            window.vue.$message.warning(obj.Message)
          }
          break
        case 40008:
          //退出组
          if (obj.Result == 2200) {
            console.log('收到退出组响应')
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_CLOSE)
          } else if (obj.Result == 2214) {
            window.vue.$message.warning('退出组失败')
          } else if (obj.Result == 2299) {
            cope2209DropLine()
          } else {
            window.vue.$message.warning(obj.Message)
          }
          break
        case 40009:
          //申请话权成功
          if (obj.Result == 2200) {
            console.log('申请话权成功')
            window.vue.$store.commit('setSpeakerId', window.vue.$store.getters.getMyUserId)
            window.vue.$store.getters.getAllUserList[window.vue.$store.getters.getMyUserId].tokenState = true

            IntercomInfo = {
              groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
              groupId: calling_group_id,
              state: '调度台正在讲话',
              discoursePower: '松开鼠标左键或空格键释放话权',
              img: require('../assets/mainFrame/talk_bt_Talkingback.png'),
            }
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
          } else if (obj.Result == 2299) {
            cope2209DropLine()
          } else {
            switch (obj.Result) {
              case 2215:
                tokenState = '用户不在组中'
                break
              case 2216:
                tokenState = '用户被禁言'
                break
              case 2217:
                tokenState = '话权被占用'
                break
              case 2218:
                tokenState = '空闲'
                break
              case 2223:
                tokenState = '未连接坐席，无法发起对讲'
                // 只有用昊畅的对讲插件才会有这项提示
                break
              default:
                tokenState = '接收到未知话权事件'
                break
            }

            IntercomInfo = {
              groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
              groupId: calling_group_id,
              state: tokenState,
              discoursePower: '按下鼠标左键或空格键申请话权',
              img: '',
            }
            if (obj.Type == 4) {
              IntercomInfo.img = require('../assets/mainFrame/talk_bt_Talking.png')
            } else {
              IntercomInfo.img = require('../assets/mainFrame/talk_bt_TalkingFail.png')
            }
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
          }
          break
        case 40010:
          //释放话权成功
          if (obj.Result == 2200) {
            window.vue.$store.commit('setSpeakerId', window.vue.$store.getters.getMyUserId)
            window.vue.$store.commit('setSpeakerId', null)
            if(window.vue.$store.getters.getAllUserList[window.vue.$store.getters.getMyUserId]){
              window.vue.$store.getters.getAllUserList[window.vue.$store.getters.getMyUserId].tokenState = false
            }
            console.log('释放话权成功')
            IntercomInfo = {
              groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
              groupId: calling_group_id,
              state: '空闲',
              discoursePower: '按下鼠标左键或空格键申请话权',
              img: require('../assets/mainFrame/talk_bt_Talking.png'),
            }
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
          } else if (obj.Result == 2299) {
            cope2209DropLine()
          } else {
            window.vue.$message.warning(obj.Message)
          }
          break
        case 40012:
          //用户话权变化通知, 申请话权回应公用此包
          //1预定义组2临时组
          // my_calling_group_id
          // listenningGroups
          if (obj.GroupId) {
            if (obj.GroupId == my_calling_group_id) {
              calling_group_id = obj.GroupId
            } else {
              if(window.vue.$store.getters.getAllGroupList[obj.GroupId]){
                calling_group_id = obj.GroupId
                window.vue.$store.commit('updateRecentGroupList', calling_group_id)
              }else{
                return
              }
            }
          }

          switch (obj.Type) {
            case 0:
              tokenState = window.vue.$store.getters.getAllUserList[obj.TokenOwner].name + '正在讲话'
              window.vue.$store.commit('setSpeakerId', obj.TokenOwner)
              IntercomInfo = {
                groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
                groupId: calling_group_id,
                state: tokenState,
                discoursePower: '按下鼠标左键或空格键申请话权',
                img: require('../assets/mainFrame/talk_bt_Talking.png'),
              }
              VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
              window.vue.$store.getters.getAllUserList[obj.TokenOwner].tokenState = true
              break
            case 1:
              tokenState = '用户不在组中'
              break
            case 2:
              tokenState = '用户被禁言'
              break
            case 3:
              tokenState = '话权被占用'
              break
            case 4:
              if(!calling_group_id){
                return
              }
              tokenState = '空闲'
              IntercomInfo = {
                groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
                groupId: calling_group_id,
                state: tokenState,
                discoursePower: '按下鼠标左键或空格键申请话权',
                img: require('../assets/mainFrame/talk_bt_Talking.png'),
              }
              VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
                if(window.vue.$store.getters.getAllUserList[window.vue.$store.state.getSpeakerId]){
                  window.vue.$store.getters.getAllUserList[window.vue.$store.state.getSpeakerId].tokenState = false
                }
              window.vue.$store.commit('setSpeakerId', null)

              break
            case 5:
              tokenState = '话权释放失败'
              break
            default:
              tokenState = '接收到未知话权事件'
              break
          }
          if (window.vue.$store.getters.getMyUserId == obj.TokenOwner && obj.Type == 0) {
            IntercomInfo = {
              groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
              groupId: calling_group_id,
              state: '调度台正在讲话',
              discoursePower: '松开鼠标左键或空格键释放话权',
              img: require('../assets/mainFrame/talk_bt_Talkingback.png'),
            }
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
          } else {
            IntercomInfo = {
              groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
              groupId: calling_group_id,
              state: tokenState,
              discoursePower: '按下鼠标左键或空格键申请话权',
              img: '',
            }
            if (obj.Type == 4) {
              IntercomInfo.img = require('../assets/mainFrame/talk_bt_Talking.png')
            } else if (
              tokenState == window.vue.$store.getters.getAllUserList[obj.TokenOwner].name + '正在讲话' &&
              !global_talkback_talk_state
            ) {
              //判断鼠标左键或者空格键是否按下
              IntercomInfo.img = require('../assets/mainFrame/talk_bt_otherTalkingback.png')
            } else {
              IntercomInfo.img = require('../assets/mainFrame/talk_bt_TalkingFail.png')
            }
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
          }

          break
        case 40013:
          //通知前端调度台有用户信息需要更新，需要重新请求用户列表
          // 已经不需要根据这个通知刷新了，走MQ通知
          break

        case 40017:
          // 其他终端登录通知
          window.vue
            .$confirm('其他终端登录！', {
              confirmButtonText: '确定',
              showCancelButton: false,
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              type: 'warning',
            })
            .then(() => {
              Cookies.clearCookies()
              window.vue.$router.push({ path: './login' })
            })
          break
        case 40018:
          //语音服务器重新登录
          console.log('收到40018，语音服务器重新登陆')
          reloginTalkServer()
          break

        case 40020:
          //麦克PTT键状态(昊畅坐席)
          if (obj.Pressed) {
            onPttKeyDown()
          } else {
            onPttKeyUp()
          }
          break
        case 40021:
          // 发送短消息
          // 已经不需要这个通知了，走接口发送短消息
          break
        case 40022:
          //短消息通知
          // 判断群组id是否是当前打开的群组 以及短消息界面是否显示(未完成)
          if (GLOBAL_C_GROUPCHAT_GID == obj.Gid) {
            // 更新短消息界面
          }
          break
        case 40023:
          //短消息历史查询
          // 不需要插件查询短消息了 走接口查询
          break
        case 40024:
          // 查询当前账户状态
          if (!obj.InVideo) {
            Cookies.clearCookies()
            window.vue.$router.push({ path: './login' })
          } else {
            window.vue.$message.warning('正在实时视频，请挂断当前视频，再发起连接')
          }
          break

        case 40026:
          //调度员关联用户被动被拉进临时组
          if (obj.Gid != null) {
            let groupName = window.vue.$store.getters.getAllUserList[obj.Gid].name
            calling_group_id = obj.Gid
            IntercomInfo = {
              groupName: groupName,
              groupId: calling_group_id,
              state: '空闲',
              discoursePower: '按下鼠标左键或空格键申请话权',
              img: require('../assets/mainFrame/talk_bt_Talking.png'),
            }
            VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
            window.vue.$message.info('加入临时组：' + groupName)
          }
          break
        case 40027:
          //批量添加监听
          if (obj.Result == 2220) {
          } else if (obj.Result == 2299) {
            cope2209DropLine()
          } else {
            window.vue.$message.warning(obj.Message)
          }
          break
        case 40028:
          window.vue.$message.warning(obj.Message)
          break
        case 40031:
          window.vue.$store.commit('updateShowTips', true)
          window.vue.$store.commit('updateLoginStates', '对讲账号已掉线，正在重新登录，请稍后重试')
          reloginTalkServer()
          break
        case 40032:
          // 对讲插件掉线后会自动重新登录，重新登陆后，api接口的token会更新
          // 此通知已弃用
          break
        case 40033:
          // 有临时组被创建通知
          break
        case 40034:
          // 有临时组被解散通知
          break
        default:
      }
    }
    global_talkback_server_socket.onclose = function () {
      console.log('语音websocket onclose')
      if (global_talkback_server_socket) {
        global_talkback_server_socket.close()
        global_talkback_server_socket = null
      }
      setTimeout(function () {
        initVoiceWebsocket()
      }, 5000)
    }
    global_talkback_server_socket.onerror = function (errorEvent) {
      console.log('语音websocket onerror: ' + errorEvent)
      window.vue.$store.commit('updateShowTips', true)
      window.vue.$store.commit('updateLoginStates', '未打开对讲插件，无法进行对讲')
      // 对讲插件未打开时，打开对讲插件
      if (Intercom_plugin == null) {
        Intercom_plugin = 1
        window.location.href = 'uniptt://message'
      }
      setTimeout(function () {
        initVoiceWebsocket()
      }, 10000)
    }
    resetTalkReconnectTimer()
  } else {
    window.vue.$store.commit('updateShowTips', true)
    window.vue.$store.commit('updateLoginStates', '您的浏览器不支持WebSocket！')
    // 浏览器不支持 WebSocket
    window.vue
      .$confirm('您的浏览器不支持 WebSocket！', {
        confirmButtonText: '确定',
        showCancelButton: false,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: 'warning',
      })
      .then(() => {})
  }
}

function resetTalkReconnectTimer() {
  clearTalkReconnectTimer()
  TALK_SERVER_RECONNECT_TIMER = setInterval(function () {
    //61秒判断一次（经测试，对讲服务器websocket连接断开1分钟就可以触发onclose）
    if (!global_talkback_server_socket) {
      if (
        global_talkback_server_socket.readyState == WebSocket.CLOSING ||
        global_talkback_server_socket.readyState == WebSocket.CONNECTING
      ) {
        console.log('对讲websocket busy readyState= ' + global_talkback_server_socket.readyState)
      } else if (global_talkback_server_socket.readyState == WebSocket.CLOSED) {
        global_talkback_server_socket = null
        initVoiceWebsocket()
      }
    } else {
      initVoiceWebsocket()
    }
  }, 61000)
}

function clearTalkReconnectTimer() {
  if (TALK_SERVER_RECONNECT_TIMER) {
    clearInterval(TALK_SERVER_RECONNECT_TIMER)
    TALK_SERVER_RECONNECT_TIMER = null
  }
}

//语音服务器重新登录，websocket和服务器断开连接
function reloginTalkServer() {
  if (global_talkback_server_socket != null) {
    clearTalkReconnectTimer()
    var loginName = Cookies.getCookie(Cookies.KeyUserName)
    var loginPwd = Cookies.getCookie(Cookies.KeyPassword)
    var obj = {
      Code: 40000,
      SerialNum: 1,
      LoginName: loginName,
      Password: loginPwd,
      Lang: 'zh',
    }
    var sendData = JSON.stringify(obj)
    global_talkback_server_socket.send(sendData)
  } else {
    initVoiceWebsocket()
  }
}
//发送消息到对讲websocket
function judgeTalkWebsoketConnect(obj, fs, fc, bHideError) {
  GLOBAL_C_GROUPCHAT_GID = obj.Gid
  if (document.readyState == 'complete') {
    //判断页面是否加载完成
    if (
      global_talkback_server_socket &&
      global_talkback_server_socket.readyState == WebSocket.OPEN &&
      global_websocket_already_login
    ) {
      if (fs) {
        fs()
      }
      var sendData = JSON.stringify(obj)
      global_talkback_server_socket.send(sendData)
    } else {
      if (!bHideError) {
        window.vue.$store.commit('updateShowTips', true)
        window.vue.$message.warning(window.vue.$store.state.loginStates)
      }
      if (global_talkback_server_socket) {
        if (global_talkback_server_socket.readyState == WebSocket.OPEN) {
          console.log('websocket 已连接，未登录成功')
        } else if (global_talkback_server_socket.readyState == WebSocket.CLOSED) {
          global_talkback_server_socket.close()
          global_talkback_server_socket = null
        }
      }
      if (fc) {
        fc()
      }
    }
  }
  if (obj.Code == 40021) {
    let now_send_message = obj.Msg
  }
}

// 对讲服务器掉线重新身份认证
function cope2209DropLine() {
  window.vue.$store.commit('updateShowTips', true)
  window.vue.$store.commit('updateLoginStates', '对讲账号已掉线，正在重新登录，请稍后重试')
  reloginTalkServer()
}

//按下鼠标申请话权
function onLeftclickKeyDown() {
  var fs = function () {
    IntercomInfo = {
      groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
      groupId: calling_group_id,
      state: '正在申请话权',
      discoursePower: '按下鼠标左键或空格键申请话权',
      img: require('../assets/mainFrame/talk_bt_Talking.png'),
    }
    VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
  }
  var obj = {
    Code: 40009,
    SerialNum: 1,
  }
  global_talkback_talk_state = true

  judgeTalkWebsoketConnect(obj, fs)
}

//鼠标左键松开释放话权
function onLeftclickKeyUp() {
  var fs = function () {
    global_talkback_talk_state = false

    IntercomInfo = {
      groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
      groupId: calling_group_id,
      state: '释放话权',
      discoursePower: '松开鼠标左键或空格键释放话权',
      img: require('../assets/mainFrame/talk_bt_Talkingback.png'),
    }
    VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
  }
  var obj = {
    Code: 40010,
    SerialNum: 1,
  }
  judgeTalkWebsoketConnect(obj, fs)
}

// 空格键按下执行的方法
function onPttKeyDown() {
  // event.preventDefault() //加上这句话F12就不好用了，但是不加按空格键对讲的时候滚动条会滚动
  if (global_talkback_talk_state) {
    //判断是不是第一个down,如果不是就不执行这个函数
    return
  }
  global_talkback_talk_state = true

  var fs = function () {
    IntercomInfo = {
      groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
      groupId: calling_group_id,
      state: '正在申请话权',
      discoursePower: '按下鼠标左键或空格键申请话权',
      img: require('../assets/mainFrame/talk_bt_Talking.png'),
    }
    VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
  }
  var obj = {
    Code: 40009,
    SerialNum: 1,
  }
  judgeTalkWebsoketConnect(obj, fs)
}

// 空格键松开执行的方法
function onPttKeyUp() {
  global_talkback_talk_state = false
  var fs = function () {
    IntercomInfo = {
      groupName: window.vue.$store.getters.getAllGroupList[calling_group_id].name,
      groupId: calling_group_id,
      state: '释放话权',
      discoursePower: '松开鼠标左键或空格键释放话权',
      img: require('../assets/mainFrame/talk_bt_Talkingback.png'),
    }
    VueEvent.eventBus.$emit(VueEvent.EVT_INTERCOM_SHOW, IntercomInfo)
  }
  var obj = {
    Code: 40010,
    SerialNum: 1,
  }
  judgeTalkWebsoketConnect(obj, fs)
}

//发起预定义组呼
function pregroupCall(groupId, callback) {
  my_calling_group_id = groupId
  calling_group_id = groupId
  var obj = {
    Code: 40006,
    SerialNum: 1,
    Gid: groupId,
  }
  judgeTalkWebsoketConnect(obj, callback)
}

// 群组取消监听
function unlistenGroup(gid, callback) {
  if (typeof gid != 'number') {
    groupid_to_unlisten = Number(gid)
  } else {
    groupid_to_unlisten = gid
  }
  monitorCallback = callback
  var obj = {
    Code: 40005,
    SerialNum: 1,
    Gid: groupid_to_unlisten,
    Priority: 1,
  }
  judgeTalkWebsoketConnect(obj)
}

// 群组添加监听
function listenGroup(gid, callback) {
  if (typeof gid != 'number') {
    groupid_to_listen = Number(gid)
  } else {
    groupid_to_listen = gid
  }
  monitorCallback = callback
  var obj = {
    Code: 40004,
    SerialNum: 1,
    Gid: groupid_to_listen,
    Priority: 1,
  }
  judgeTalkWebsoketConnect(obj)
}

// 退出登录
function logout() {
  var obj = {
    Code: 40001,
    SerialNum: global_SerialNum++,
  }
  judgeTalkWebsoketConnect(obj)
}

export default {
  initVoiceWebsocket,
  pregroupCall,
  listenGroup,
  unlistenGroup,
  logout,
  judgeTalkWebsoketConnect,
  onPttKeyDown,
  onPttKeyUp,
  onLeftclickKeyDown,
  onLeftclickKeyUp,
}
