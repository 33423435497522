import Vue from 'vue'
var eventBus = new Vue()
/**
 * 事件，更换百度地图主题样式
 */
const EVT_SWITCH_BDMAP_STYLE = 'EVT_SWITCH_BDMAP_STYLE'

/**
 * 事件，地图显示内容变化（在线，离线，标注...）
 *  payload: 无
 */
//const EVT_MAP_CONTENT_CHANGED = 'EVT_MAP_CONTENT_CHANGED'

/**
 * 事件，通知地图居中显示用户、围栏等单个对象
 *  payload:  {Object} obj,
 *     {String} obj.type 显示对象类型，用户，围栏，区域划分，巡更点，巡更路线,标注点
 *       "user", "fence", "annotation_region", "beacon", "route" , "annotation"
 *    {Number} obj.id 对象的主键id
 */
const EVT_MAP_CENTER_DISPLAY = 'EVT_MAP_CENTER_DISPLAY'

/**
 * 事件，通知地图取消居中显示用户示用户、围栏等单个对象
 *  payload:  {Object} obj,
 *     {String} obj.type 显示对象类型，用户，围栏，区域划分，巡更点，巡更路线
 *       "user", "fence", "annotation_region", "beacon", "route" , "annotation"
 */
const EVT_MAP_CANCEL_CENTER_DISPLAY = 'EVT_MAP_CANCEL_CENTER_DISPLAY'

/**
 * 事件，通知地图添加定位跟踪
 *  payload:  {Number[]}uids
 */
const EVT_MAP_TRACE_USERS = 'EVT_MAP_TRACE_USERS'

/**
 * 围栏列表刷新
 * payload: 无
 */
const EVT_FENCE_LIST_REFRESH = 'EVT_FENCE_LIST_REFRESH'

/**
 * 标注点列表刷新
 * payload: 无
 */
const EVT_TAG_LIST_REFRESH = 'EVT_TAG_LIST_REFRESH'

/**
 * 巡更点列表刷新
 * payload: 无
 */
const EVT_BEACON_LIST_REFRESH = 'EVT_BEACON_LIST_REFRESH'
/**
 * 信标点列表刷新
 * payload: 无
 */
const EVT_LOCATION_BEACON_LIST_REFRESH = 'EVT_LOCATION_BEACON_LIST_REFRESH'

/**
 * 预采点列表刷新
 * payload: 无
 */
const EVT_SAMPLE_POINT_LIST_REFRESH = 'EVT_SAMPLE_POINT_LIST_REFRESH'

/**
 * 用户列表刷新
 * payload: 无
 */
const EVT_USER_LIST_REFRESH = 'EVT_USER_LIST_REFRESH'

/**
 * 关闭百度地图所有操作并清除信息
 */
const EVT_CLOSE_MAP_OPERATION = 'EVT_CLOSE_MAP_OPERATION'

/**
 * 编辑围栏区域
 * payload:  fenceId: Number
 */
const EVT_EDIT_FENCE_IN_MAP = 'EVT_EDIT_FENCE_IN_MAP'

/**
 * 编辑围栏区域完成
 */
const EVT_EDIT_FENCE_COMPLETE = 'EVT_EDIT_FENCE_COMPLETE'

/**
 * 围栏模块通过广播通知地图模块取消围栏的编辑、创建、显示
 * payload:  无
 */
const EVT_CANCEL_FENCE_OPEATION = 'EVT_CANCEL_FENCE_OPEATION'

/**
 * 围栏弹窗通过广播通知地图模块更新围栏显示
 * payload:  无
 */
const EVT_FENCE_EDITING = 'EVT_FENCE_EDITING'

/**
 * 地图创建围栏结束后，通知主页展示围栏组件弹窗
 * payload: [Array] 围栏坐标点集合, null 代表取消了操作
 */
const EVT_CREATE_FENCE_COMPLETED = 'EVT_CREATE_FENCE_COMPLETED'

/**
 * 编辑区域划分区域
 * payload:  {Number}id
 */
const EVT_EDIT_ANNOTATION_REGION_IN_MAP = 'EVT_EDIT_ANNOTATION_REGION_IN_MAP'

/**
 * 地图删除区域划分
 * payload:  {Number}id
 */
const EVT_DEL_ANNOTATION_REGION_IN_MAP = 'EVT_DEL_ANNOTATION_REGION_IN_MAP'

/**
 * 区域划分模块通过广播通知地图模块创建区域划分
 * payload:  无
 */
const EVT_CREATE_ANNOTATION_REGION_IN_MAP = 'EVT_CREATE_ANNOTATION_REGION_IN_MAP'

/**
 * 区域划分模块通过广播通知地图模块取消围栏的编辑、创建、显示
 * payload:  无
 */
const EVT_CANCEL_ANNOTATION_REGION_OPEATION = 'EVT_CANCEL_ANNOTATION_REGION_OPEATION'

/**
 * 通知地图编辑标注
 */
const EVT_TAGGING_EDIT_IN_MAP = 'EVT_TAGGING_EDIT_IN_MAP'


/**
 * 通知地图编辑巡更点
 */
const EVT_BEACON_EDIT_IN_MAP = 'EVT_BEACON_EDIT_IN_MAP'

/**
 * 通知单个用户的在线状态发生了变化
 * payload: Object{{Number}uid, {Boolean}online}
 */
const EVT_USER_ONLINE_CHANGED = 'EVT_USER_ONLINE_CHANGED'

/**
 * 通知单个用户的定位发生了变化
 * payload: {Number}
 */
const EVT_USER_LOCATION_CHANGED = 'EVT_USER_LOCATION_CHANGED'

/**
 * 通知 MQTT连接成功
 * payload: 无
 */
const EVT_MQTT_CONNECTED = 'EVT_MQTT_CONNECTED'

/**
 * 通知 MQTT连接被异常断开
 * payload: 无
 */
const EVT_MQTT_ERROR = 'EVT_MQTT_ERROR'

/**
 * 收到SOS报警弹框展示
 */
const EVT_SOS_WARNING_SHOW = 'EVT_SOS_WARNING_SHOW'

/**
 * 对讲弹窗展示
 */
const EVT_INTERCOM_SHOW = 'EVT_INTERCOM_SHOW'

/**
 * 对讲弹窗关闭
 */
const EVT_INTERCOM_CLOSE = 'EVT_INTERCOM_CLOSE'

/**
 * 子组件页面互相调用展示
 * payload: index: Number 5=任务管理 18=语音播报
 */
const EVT_SIDEBAR_OPEN_CHILD = 'EVT_SIDEBAR_OPEN_CHILD'

/**
 * 单人视频会商
 */
const EVT_SINGLE_PERSON_VIDEO = 'EVT_SINGLE_PERSON_VIDEO'

/**
 * 多人视频会商
 */
const EVT_MULTI_PLAYER_VIDEO = 'EVT_MULTI_PLAYER_VIDEO'

/**
 * 终端视频推流
 */
const EVT_PUSH_VIDEO = 'EVT_PUSH_VIDEO'

/**
 * 加入自己创建的视频会议
 */
const EVT_JOIN_MY_METTING_VIDEO = 'EVT_JOIN_MY_METTING_VIDEO'

/**
 * 加入不是自己创建的视频会议
 */
const EVT_JOIN_METTING_VIDEO = 'EVT_JOIN_METTING_VIDEO'

/**
 * 关闭终端视频推流观看
 */
const EVT_CLOSE_PUSH_VIDEO = 'EVT_CLOSE_PUSH_VIDEO'

/**
 * 关闭单人视频会商
 */
const EVT_CLOSE_SINGLE_PERSON_VIDEO = 'EVT_CLOSE_SINGLE_PERSON_VIDEO'

/**
 * 关闭多人视频会商
 */
const EVT_CLOSE_MULTI_PLAYER_VIDEO = 'EVT_CLOSE_MULTI_PLAYER_VIDEO'

/**
 * 退出正在参加的不是自己创建的会议
 */
const EVT_CLOSE_METTING_VIDEO = 'EVT_CLOSE_METTING_VIDEO'

/**
 * 退出正在参加的自己创建的会议
 */
const EVT_CLOSE_MY_METTING_VIDEO = 'EVT_CLOSE_MY_METTING_VIDEO'

/**
 * 退出用户视频上传回放
 */
const EVT_CLOSE_PUSH_VIDEO_PLAYBACK = 'EVT_CLOSE_PUSH_VIDEO_PLAYBACK'

/**
 * 退出会议回放
 */
const EVT_CLOSE_METTING_VIDEO_PLAYBACK = 'EVT_CLOSE_METTING_VIDEO_PLAYBACK'

/**
 * 更新会议列表
 */
const EVT_REFRESH_METTING_LIST = 'EVT_REFRESH_METTING_LIST'

/**
 * 打开历史轨迹查询弹窗
 * payload: {Number}uid
 */
const EVT_SHOW_HISTORICAL_TRACK_TIME_SELECT = 'EVT_SHOW_HISTORICAL_TRACK_TIME_SELECT'

/**
 * 历史轨迹查询时间已经选择好
 * payload {
 *          uid: {Number},
 *         startTime: {Number} linux UTC ms,
 *         endTime: {Number} linux UTC ms
 *         }
 */
const EVT_HISTORICAL_TRACK_TIME_SELECTED= "EVT_HISTORICAL_TRACK_TIME_SELECTED"


/**
 * 通知主界面打开用户列表弹窗
 * payload {String} 用户状态， 'online', 'offline', 'inroom', 'outdoor'
 */
const EVT_SHOW_MAP_USER_LIST_BY_STATUS = "EVT_SHOW_MAP_USER_LIST_BY_STATUS"

/**
 * 任务详情弹窗展示
 */
const EVT_SHOW_TASK_DETAILS = 'EVT_SHOW_TASK_DETAILS'

/**
 * 事件转为任务时 新建任务的弹窗
 */
const EVT_SHOW_CREATE_TASK = 'EVT_SHOW_CREATE_TASK'

/**
 * 事件转为任务后，更新事件列表信息
 */
const EVT_TASK_SUCCESS_UPDATE_EVENT = 'EVT_TASK_SUCCESS_UPDATE_EVENT'

/**
 * 通知 MQTT收到事件更新通知
 */
const EVT_MQTT_EVENT_UPDATE = 'EVT_MQTT_EVENT_UPDATE'

/**
 * 通知 MQTT收到请假更新通知
 */
const EVT_MQTT_LEAVE_UPDATE = 'EVT_MQTT_LEAVE_UPDATE'

/**
 * 通知 MQTT收到消息助手更新通知
 */
const EVT_MQTT_MESSAGE_UPDATE = 'EVT_MQTT_MESSAGE_UPDATE'

/**
 * 通知 MQTT收到群组录音记录更新通知
 */
const EVT_MQTT_GROUP_VOICE_UPDATE = 'EVT_MQTT_GROUP_VOICE_UPDATE'

/**
 * 通知 MQTT收到群组消息更新通知
 */
const EVT_MQTT_GROUP_MESSAGE_UPDATE = 'EVT_MQTT_GROUP_MESSAGE_UPDATE'

/**
 * 通知 MQTT收到媒体记录更新通知
 */
const EVT_MQTT_MEDIA_RECORD_UPDATE = 'EVT_MQTT_MEDIA_RECORD_UPDATE'

/**
 * 通知 MQTT收到任务更新通知
 */
const EVT_MQTT_TASK_UPDATE = 'EVT_MQTT_TASK_UPDATE'

/**
 * 通知 MQTT收到强制会议成员下线通知
 */
const EVT_MQTT_METTING_KICK = 'EVT_MQTT_METTING_KICK'

/**
 * 通知 MQTT收到会议结束通知
 */
const EVT_MQTT_METTING_DISMISS = 'EVT_MQTT_METTING_DISMISS'

/**
 * 通知 MQTT收到终端停止推流通知
 */
const EVT_MQTT_PUSH_UNPUBLISH = 'EVT_MQTT_PUSH_UNPUBLISH'

/**
 * 通知 MQTT收到终端拒绝视频会商通知
 */
const EVT_MQTT_CONSULTATION_REJECT = 'EVT_MQTT_CONSULTATION_REJECT'

/**
 * 通知 MQTT收到会议设置主讲人通知
 */
const EVT_MQTT_METTING_SPEAKER = 'EVT_MQTT_METTING_SPEAKER'

/**
 * 通知 MQTT收到任务附件上传通知
 */
const EVT_MQTT_TASK_ENCLOSURE = 'EVT_MQTT_TASK_ENCLOSURE'

/**
 * 通知 MQTT收到事件附件上传通知
 */
const EVT_MQTT_EVENT_ENCLOSURE = 'EVT_MQTT_EVENT_ENCLOSURE'
/**
 * 通知 区域划分创建区域
 */
const EVT_CREATE_REGION = 'EVT_CREATE_REGION'
/**
 * 通知 区域划分编辑区域
 */
const EVT_EDIT_REGION_IN_MAP = 'EVT_EDIT_REGION_IN_MAP'
/**
 * 通知 页面关闭清除显示在地图的单个实例
 */
const EVT_CLEAR_ONE_ON_MAP = 'EVT_CLEAR_ONE_ON_MAP'
/**
 * 通知 地图清空定位跟踪marker点
 */
const EVT_CLEAR_TRACE = 'EVT_CLEAR_TRACE'
/**
 * 通知 关闭当前的视频会商（单人或多人视频会商）
 */
const EVT_CLOSE_VIDEO_CONFERENCING = 'EVT_CLOSE_VIDEO_CONFERENCING'
/**
 * 通知 清除某一个定位跟踪用户在地图上的轨迹
 */
const EVT_CLEAR_ONE_TRACE_USER = 'EVT_CLEAR_ONE_TRACE_USER'
/**
 * 通知 处理和显示厂区及建筑物
 */
const EVT_ADD_FACTORY_BUILDING = 'EVT_ADD_FACTORY_BUILDING'
/**
 * 通知 全屏时，关闭菜单的窗体显示
 */
const EVT_FULL_SCREEN_CLOSE_FORMS = 'EVT_FULL_SCREEN_CLOSE_FORMS'
/**
 * 通知 收到卫星电话开关机MQ通知
 */
const EVT_MQTT_POWER_STATUS = 'EVT_MQTT_POWER_STATUS'
/**
 * 通知 收到在线变化MQ通知更新右下角上下线状态
 */
const EVT_MQTT_USER_STATUS = 'EVT_MQTT_USER_STATUS'
/**
 * 通知 轨迹回放时隐藏所有菜单，退出轨迹回放展示所有菜单
 */
const EVT_SHOW_MAP_MENU_STATUS = 'EVT_SHOW_MAP_MENU_STATUS'
/**
 * 通知 地图初始化完成后，检查地图缩放级别是否<18,若<18需要隐藏楼层和建筑物
 */
const EVT_SWITCH_FLOOR_SHOW = 'EVT_SWITCH_FLOOR_SHOW'
/**
 * 圈呼新增语音播报的弹窗
 */
const EVT_SHOW_CREATE_VOICE = 'EVT_SHOW_CREATE_VOICE'

export default {
  eventBus,

  EVT_SWITCH_BDMAP_STYLE,
 // EVT_MAP_CONTENT_CHANGED,
  EVT_MAP_CENTER_DISPLAY,
  EVT_MAP_CANCEL_CENTER_DISPLAY,
  EVT_MAP_TRACE_USERS,

  EVT_FENCE_LIST_REFRESH,
  EVT_TAG_LIST_REFRESH,
  EVT_BEACON_LIST_REFRESH,
  EVT_LOCATION_BEACON_LIST_REFRESH,
  EVT_SAMPLE_POINT_LIST_REFRESH,
  EVT_USER_LIST_REFRESH,
  EVT_CLOSE_MAP_OPERATION,

  EVT_EDIT_FENCE_IN_MAP,
  EVT_EDIT_FENCE_COMPLETE,
  EVT_CANCEL_FENCE_OPEATION,
  EVT_FENCE_EDITING,
  EVT_CREATE_FENCE_COMPLETED,

  EVT_EDIT_ANNOTATION_REGION_IN_MAP,
  EVT_DEL_ANNOTATION_REGION_IN_MAP,
  EVT_CREATE_ANNOTATION_REGION_IN_MAP,
  EVT_CANCEL_ANNOTATION_REGION_OPEATION,
  EVT_TAGGING_EDIT_IN_MAP,
  EVT_BEACON_EDIT_IN_MAP,

  EVT_USER_ONLINE_CHANGED,
  EVT_USER_LOCATION_CHANGED,
  EVT_MQTT_CONNECTED,
  EVT_MQTT_ERROR,
  EVT_SOS_WARNING_SHOW,
  EVT_INTERCOM_SHOW,
  EVT_INTERCOM_CLOSE,
  EVT_SIDEBAR_OPEN_CHILD,
  EVT_SINGLE_PERSON_VIDEO,
  EVT_MULTI_PLAYER_VIDEO,
  EVT_PUSH_VIDEO,
  EVT_JOIN_MY_METTING_VIDEO,
  EVT_JOIN_METTING_VIDEO,
  EVT_CLOSE_PUSH_VIDEO,
  EVT_CLOSE_SINGLE_PERSON_VIDEO,
  EVT_CLOSE_MULTI_PLAYER_VIDEO,
  EVT_CLOSE_METTING_VIDEO,
  EVT_CLOSE_MY_METTING_VIDEO,
  EVT_CLOSE_PUSH_VIDEO_PLAYBACK,
  EVT_CLOSE_METTING_VIDEO_PLAYBACK,
  EVT_REFRESH_METTING_LIST,
  EVT_SHOW_HISTORICAL_TRACK_TIME_SELECT,
  EVT_HISTORICAL_TRACK_TIME_SELECTED,
  EVT_SHOW_MAP_USER_LIST_BY_STATUS,
  EVT_SHOW_TASK_DETAILS,
  EVT_SHOW_CREATE_TASK,
  EVT_TASK_SUCCESS_UPDATE_EVENT,
  EVT_MQTT_EVENT_UPDATE,
  EVT_MQTT_LEAVE_UPDATE,
  EVT_MQTT_MESSAGE_UPDATE,
  EVT_MQTT_GROUP_VOICE_UPDATE,
  EVT_MQTT_GROUP_MESSAGE_UPDATE,
  EVT_MQTT_MEDIA_RECORD_UPDATE,
  EVT_MQTT_TASK_UPDATE,
  EVT_MQTT_METTING_KICK,
  EVT_MQTT_METTING_DISMISS,
  EVT_MQTT_PUSH_UNPUBLISH,
  EVT_MQTT_CONSULTATION_REJECT,
  EVT_MQTT_METTING_SPEAKER,
  EVT_MQTT_TASK_ENCLOSURE,
  EVT_MQTT_EVENT_ENCLOSURE,

  EVT_CREATE_REGION,
  EVT_EDIT_REGION_IN_MAP,
  EVT_CLEAR_ONE_ON_MAP,
  EVT_CLEAR_TRACE,
  EVT_CLOSE_VIDEO_CONFERENCING,
  EVT_CLEAR_ONE_TRACE_USER,
  EVT_ADD_FACTORY_BUILDING,

  EVT_FULL_SCREEN_CLOSE_FORMS,

  EVT_MQTT_POWER_STATUS,
  EVT_MQTT_USER_STATUS,
  EVT_SHOW_MAP_MENU_STATUS,
  EVT_SWITCH_FLOOR_SHOW,
  EVT_SHOW_CREATE_VOICE
}
