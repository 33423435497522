<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss">
// 将边距变为0
body{
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#app {
  width: 100%;
  height: 100%;
}

// 滚动条start
// 定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸
::-webkit-scrollbar {
  width: 10px;
  height: 14px;
  background-color: #ffffff;
}
// 定义滚动条轨道 内阴影+圆角
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #0000004d;
  box-shadow: none;
  border-radius: 1px;
  background-color: #b3c8e7;
}
.scroller_wrapper {
  color: #ffffff;
  position: absolute;
  z-index: 1;
  -webkit-tap-highlight-color: #00000000;
  width: 360px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none;
}
.scroller_wrapper ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: left;
}
.scroller_pullUp,
.scroller_pullUp_flip,
.scroller_pullUp_loading {
  line-height: 40px;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  background-color: #ffffff00;
}
.group_cRecord_pullUp_tips {
  background-color: #ffffff00;
  color: #ffffff;
}
// 定义滑块 内阴影+圆角
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
  box-shadow: none;
  background-color: #1d74bf;
}
// 滚动条end

// 主题切换
:root {
  --bg_color: #12223d;
  --bg_color_transparent: #12223dde;
  --bx_sd_color: #009dff;
  --login_text_color: #fff;
  --login_bg_color: #12223d;
  --head_bd_color: #c4d0e1;
  --head_bg_color: #1858a3;
  --head_text_color: #fff;
  --more_text_color: #f6c718;
  --search_bd_color: #ccc;
  --list_hover_bg_color: #0d518a;
  --group_bg_color: #0a3050;
  --online_user_color:#2ae164;

  // --bg_color: #f5f5f5;
  // --bg_color_transparent: #f5f5f5de;
  // --bx_sd_color: #a0a0a0;
  // --login_text_color: #1585d1;
  // --login_bg_color: #1585d1;
  // --head_bd_color: #64676b;
  // --head_bg_color: #f5f5f5;
  // --head_text_color: #000;
  // --more_text_color: #409eff;
  // --search_bd_color: #64676b;
  // --list_hover_bg_color: #cac8c8;
  // --group_bg_color: #fff;
  // --online_user_color:#067e2e;
}
</style>
