//values: [0, 1],
// 首页创建标注的标签类型枚举
export const ENUM_INDEX_POINT_TYPE = {
  ENUM_INDEX_POINT_TYPE_ZERO: {
    desc: 'NFC标签',
    value: 0,
  },
  ENUM_INDEX_POINT_TYPE_ONE: {
    desc: '蓝牙标签',
    value: 1,
  },
}
