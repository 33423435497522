//values: [0, 1, 2],
// 请假管理的类型枚举
export const ENUM_LEAVE_TYPE = {
  ENUM_LEAVE_TYPE_ZERO: {
    desc: '请假',
    value: 0,
  },
  ENUM_LEAVE_TYPE_ONE: {
    desc: '替班',
    value: 1,
  },
  ENUM_LEAVE_TYPE_TWO: {
    desc: '换岗',
    value: 2,
  },
}
//values: [0, 1, 2],
// 请假管理的状态枚举
export const ENUM_LEAVE_STATE = {
  ENUM_LEAVE_STATE_ZERO: {
    desc: '审核中',
    value: 0,
  },
  ENUM_LEAVE_STATE_ONE: {
    desc: '同意',
    value: 1,
  },
  ENUM_LEAVE_STATE_TWO: {
    desc: '驳回',
    value: 2,
  },
}
//values: [true, false],
// 请假管理的审核类型枚举
export const ENUM_LEAVE_EXAMINE = {
  ENUM_LEAVE_EXAMINE_TRUE: {
    desc: '同意',
    value: true,
  },
  ENUM_LEAVE_EXAMINE_FALSE: {
    desc: '驳回',
    value: false,
  },
}
