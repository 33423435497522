// 地图操作菜单状态
const MAP_MENU_ITEM_INVALID = 0
const MAP_MENU_ITEM_REFRESH = 1
const MAP_MENU_ITEM_CIRCLE_CALL = 2
const MAP_MENU_ITEM_MEASURE = 3
const MAP_MENU_ITEM_CREATE_FENCE = 4
const MAP_MENU_ITEM_CREATE_TAG = 5
const MAP_MENU_ITEM_CREATE_BEACON = 6
const MAP_MENU_ITEM_QUERY_BY_LOCATION = 7
const MAP_MENU_ITEM_DEFAULT_SHOW = 8

const MAP_MENU_ITEM_CREATE_TAG_SINGLE = 51
const MAP_MENU_ITEM_CREATE_TAG_IMPORT = 52

export default {
  MAP_MENU_ITEM_INVALID,
  MAP_MENU_ITEM_REFRESH,
  MAP_MENU_ITEM_CIRCLE_CALL,
  MAP_MENU_ITEM_MEASURE,
  MAP_MENU_ITEM_CREATE_FENCE,
  MAP_MENU_ITEM_CREATE_TAG,
  MAP_MENU_ITEM_CREATE_BEACON,
  MAP_MENU_ITEM_QUERY_BY_LOCATION,
  MAP_MENU_ITEM_DEFAULT_SHOW,
  MAP_MENU_ITEM_CREATE_TAG_SINGLE,
  MAP_MENU_ITEM_CREATE_TAG_IMPORT
}
